.navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100px;
    /* background-color: #7b42cc; */
    color: white;
    width: 100%;
}
.navbar a {
    color: white;
    text-decoration: none;
    padding: 0 10px;
    font-size: 16px;
}
.navbar a:hover {
    text-decoration: underline;
}

.container {
   
    text-align: center;
    /* background-color: #7b42cc; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.containerDiv1 {
   
    text-align: center;
   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    overflow: hidden;
}

.containerDiv2 {
   
    text-align: center;
   
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
}
.container h1 {
    font-size: 2.5rem;
}
.container p {
    font-size: 1.1rem;
    margin: 10px 0;
}

.telegrambutton {
    margin-top: 20px;
}
.telegrambutton button {
    background-color: #e4e4f1;
    border: none;
    padding: 12px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 8px;
    margin-right: 10px;
    margin-top: 10px;
}
.telegrambutton button:hover {
    background-color: #ccc;
}

.heroimage {
    width: 100%;
    max-width: 400px;
    margin: 20px auto;
}

.sectionred {
    position: relative;
    /* background-color: #8e3134; */
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
   
    flex-direction: row;
}

.sectionredDiv1 {
    /* background-color: #8e3134; */
    width: 50%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    position: relative;
    padding:30px;
}

.sectionredDiv2 {
    /* background-color: #8e3134; */
    width: 50%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 30px;
}
.sectionred h1 {
    font-size: 2rem;
}
.sectionred p {
    font-size: 1rem;
    line-height: 1.5;
    max-width: 800px;
    margin: 0 auto;
}

.tokenomics {
    position: relative;
    width: 100%;
    height: 100%;
    /* background-color: #7b42cc; */
    text-align: center;
    padding-bottom: 100px;
}
.tokenomics h2 {
    font-size: 2rem;
    margin-bottom: 10px;
}
.tokenomics p {
    font-size: 1.1rem;
}

.stats {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
    margin-top: 30px;
    width: 100%;
    align-items: center;
}
.stats div {
    width: 30%;
    text-align: center;
}
.stats img {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
}
.stats h3 {
    font-size: 1.2rem;
}
.stats p {
    font-size: 1rem;
}

.socials {

    text-align: center;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    
   
}

.socialsDiv1 {
    
    text-align: center;
    position: relative;
    /* width: 60%; */
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
    
}

.socialsDiv2 {
    background-color: #ffffff;
    color: #8e3134;
    height: 80px;
    text-align: center;
    position: relative;
    width: fit-content;
   
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
   
}
.socialsDiv3{
    background-color: #ffffff;
}
.socials img {
    width: 60px;
    height: 60px;
    margin: 15px;
}
.contract {
    margin-top: 20px;
    background-color: #ffffff;
    color: #8e3134;
    padding: 10px;
    font-size: 1.1rem;
    border-radius: 5px;
    text-align: center;
   
   
    width: 50%;
    height: 100%;
}

.footer {
   position: relative;
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
    /* background-color: #7b42cc; */
    text-align: center;
    font-size: 0.9rem;
}

.footerdiv1 {
   position: relative;
   width: 50%;
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
    text-align: center;
    font-size: 0.9rem;
    padding: 20px;
}
.footerdiv2 {
   position: relative;
   width: 100%;
   height: 100%;
   display: flex;
  
   align-items: center;
   justify-content: center;
    
    text-align: center;
    font-size: 0.9rem;
}
.footer p {
    margin: 10px 0;
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
    .stats div {
        width: 45%;
    }
    .navbar a {
        font-size: 14px;
        padding: 0 5px;
    }
    .container h1 {
        font-size: 2rem;
    }
    .container p {
        font-size: 1rem;
    }
    .sectionred h1 {
        font-size: 1.8rem;
    }
    .sectionred p {
        font-size: 0.9rem;
    }
    .tokenomics h2 {
        font-size: 1.8rem;
    }
    .tokenomics p {
        font-size: 1rem;
    }
    .container {
   
        text-align: center;
        background-color: #7b42cc;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    .containerDiv2{
        width: 100%;
    }
    .containerDiv1{
        width: 100%;
    }
    .sectionred{
        flex-direction: column;
    }
    .sectionredDiv1{
        width: 100%;
    }
    .sectionredDiv2{
        width: 100%;
    }
    .stats{
        flex-direction: column;
    }
    .socialsDiv1{
        flex-direction: column;
    }
    .footer{
        flex-direction: column;
    }
    .footerdiv1{
        width: 100%;
    }
    .footerdiv2{
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .stats div {
        width: 100%;
    }
    .navbar {
        display: flex;
        font-size: 5px;
        
    }
    .container h1 {
        font-size: 1.5rem;
    }
    .container p {
        font-size: 0.9rem;
    }
    .telegram-button button {
        padding: 10px 15px;
        font-size: 0.9rem;
    }
    .heroimage {
        max-width: 100%;
    }
    .sectionred h1 {
        font-size: 1.5rem;
    }
    .sectionred p {
        font-size: 0.9rem;
    }
    .socials img {
        width: 50px;
        height: 50px;
    }
}