@import url("https://fonts.googleapis.com/css2?family=Finger+Paint:wght@400&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.T3-main-page {
  height: auto;
  /* background-image: url(./images/chess1.jfif); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.T3-main-page .T3-cartoonparent {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.T3-first-aside-btn {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.T3-cartoonparent img {
  width: 70%;
}

.T3-second-section .T3-first-aside {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.T3-welgome {
  margin-top: 100px;
  height: auto;
  border: 10px solid #fff;
  border-radius: 20px;
  background-color: #844f36;
  max-width: 791.3px;
  font-size: 49.36px;
  letter-spacing: 7.04px;
  font-family: Inter;
  color: #fff;
  text-align: center;
  word-wrap: break-word;
}

.T3-first-aside .T3-innovadion {
  margin-top: 46px;
  max-width: 992px;
  font-size: 33.6px;
  line-height: 47px;
  font-family: "Finger Paint";
  color: #fff;
  text-align: center;
}
.T3-memecoin {
  margin-top: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  /* background-image: url("./images/rhombus.png"); */
  background-position: bottom center;
  background-repeat: no-repeat;
}
.T3-memecoin img {
  width: 50%;
}

/* Third section css starts here */

.T3-third-section {
  /* background-color: #07060d; */
}
.T3-top-aside,
.T3-bottom-aside {
  width: 100%;
  padding: 50px;
}
.T3-ghoose-parent {
  width: 60%;
  background-color: #fff;
  margin: 50px auto;
  padding: 40px;
  border-radius: 20px;
}
.T3-ghoose-child {
  font-size: 40px;
  color: #ffffff;
  text-align: center;
  background-color: #844f36;
}
.T3-four-cards-parent {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 40px 10px;
  flex-wrap: wrap;
  margin: auto;
}
.T3-four-card {
  width: 48%;
  height: 280px;
  padding: 20px;
  background-color: #98573a;
  color: #fff;
  font-size: 18px;
  border: 5px solid #fff;
  border-radius: 12px;
  overflow: hidden;
}
.T3-four-card-heafding-text {
  font-weight: 300;
  font-family: Inter;
  font-size: 15.95px;
  font-weight: 400;
  line-height: 34px;
  text-align: left;
}
.T3-four-card-para {
  font-family: Finger Paint;
  font-size: 17.8px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}

/* Fourth section css start here  */
.T3-fourth-section,
.T3-Fifth-section {
  width: 100%;
  /* background-color: #844f36; */
  padding-top: 100px;
}
.T3-fourth-section {
  width: 100%;
  /* background: url("./images/your-background-image.jpg") center/cover, #844f36; */
  background-size: cover;
  background-position: center;
  color: white;
}

.T3-Fourth-first-aside,
.T3-Fifth-first-aside {
  width: 80%;
  display: flex;
  margin: auto;
}

.T3-Fourth-first-aside .T3-snupped-image {
  width: 40%;
}
.T3-snupped-image img {
  width: 80%;
  height: 80%;
}
.T3-snupped-content {
  width: 100%;
}
.T3-snupped-heading {
  font-family: Inter;
  font-size: 67.31px;
  font-weight: 400;
  line-height: 85px;
  letter-spacing: -1.9199999570846558px;
  text-align: left;
  color: #fff;
}
.T3-snupped-para {
  font-family: Finger Paint;
  font-size: 34.7px;
  font-weight: 400;
  line-height: 52px;
  text-align: left;
  color: #fff;
}

/* Fifth section css starts here */
.T3-dog-content {
  width: 60%;
}
.T3-dog-image {
  width: 40%;
}
.T3-dog-image img {
  height: 100%;
  width: 100%;
}

.T3-sixth-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 50px 0;
  /* background-image: url(./images/chess1.jfif); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.T3-btn {
  font-family: Inter;
  color: #fff;
  font-size: 60px;
  font-weight: 400;
  border: 5px solid black;
  padding: 6px 24px;
  border-radius: 12px;
  margin-top: 50px;
  text-align: center;
  background-color: #844f36;
  background-color: #fff;
}
.T3-footerpara {
  width: 50%;
  height: auto;
  margin: auto;
  word-break: break-all;
  padding: 10px;
  color: #fff;
  background-color: #844f36;
  border-radius: 12px;
  border: 5px solid black;
  font-family: Finger Paint;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.T3-media-btn {
  min-width: 220px;
  padding: 4px;
  margin: 20px;
  border: 3px solid black;
  border-radius: 12px;
  font-family: Inter;
  font-size: 32.95px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  box-shadow: 0px 3px 0px #98573a;
  
}
.T3-spurdo {
  width: 70%;
  margin: auto;
  display: flex;
  align-content: center;
}
.T3-spurdo img {
  width: 100%;
}
.T3-footer-text {
  padding: 4px;
  margin: 20px;
  border: 3px solid black;
  border-radius: 12px;
  font-family: Inter;
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  color: #fff;
  word-break: break-all;
  background-color: #844f36;
}
@media (max-width: 908px) {
  .T3-four-card {
    height: auto;
  }
  .T3-four-card,
  .T3-dog-content {
    width: 100%;
  }
  .T3-snupped-heading {
    text-align: center;
  }
  .T3-Fourth-first-aside,
  .T3-Fifth-first-aside {
    flex-direction: column;
    align-items: center;
  }
  .T3-Fourth-first-aside .T3-snupped-image {
    width: 70%;
  }
  .T3-snupped-para,
  .T3-first-aside .T3-innovadion {
    font-size: 30px;
    word-break: break-all;
  }
  .T3-welgome,
  .T3-footerpara {
    font-size: 6vh;
  }
  .T3-welgome {
    margin: 50px 20px;
  }
  .T3-first-aside .T3-innovadion {
    margin: 0px 20px;
  }
}

@media (max-width: 520px) {
  .T3-snupped-para,
  .T3-first-aside .T3-innovadion {
    font-size: 20px;
    word-break: break-all;
  }
  .T3-spurdo {
    width: 100%;
  }
  .T3-footerpara {
    width: 90%;
  }
}
@media (max-width: 762px) {
  .T3-first-aside-btn {
    flex-direction: column;
    align-items: center;
  }
  .T3-media-btn {
    max-width: max-content;
  }
}
