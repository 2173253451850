.header {
    background-color: #fafafa;
    position: relative;
    z-index: 101;
}

header {
    display: block;
    unicode-bidi: isolate;
}

.header .wr {
    align-items: center;
    flex-direction: row;
    height: 42px;
    padding-top: 8px;
}

@media (max-width: 600px) {
    .wr {
        padding: 0 15px;
        width: calc(100% - 30px);
    }
}

.wr {
    display: flex;
    margin: 0 auto;
    max-width: 1600px;
    padding: 0 30px;
    /* width: calc(100% - 60px); */
}

.header .logoLink {
    color: inherit;
    text-decoration: none;
}

a:-webkit-any-link {
    
    cursor: pointer;
    text-decoration: underline;
}

.header .logo {
    font-size: 20px;
    margin: 0 15px 0 0;
    
}
h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}

.header .logo svg {
    display: block;
    width: 32px;
}

.header nav {
    align-items: center;
    display: flex;
}

nav {
    display: block;
    unicode-bidi: isolate;
}

.header nav a.active {
    color: #fff;
}

.header nav a:not(.button) {
    color: #24292e;
    font-size: 14px;
    font-weight: 500;
    height: 42px;
    line-height: 42px;
    margin-right: 4px;
    padding: 0 12px;
    position: relative;
    text-decoration: none !important;
    white-space: nowrap;
}

.header .spacer {
    flex-grow: 1;
}

.header nav {
    align-items: center;
    display: flex;
}
header nav.secondary a:not(.button) {
    color: #4d4d4d;
}

.header nav.secondary>* {
    margin-left: 10px;
}

.header nav a:not(.button) {
    color: #24292e;
    font-size: 14px;
    font-weight: 500;
    height: 42px;
    line-height: 42px;
    margin-right: 4px;
    padding: 0 12px;
    position: relative;
    text-decoration: none !important;
    white-space: nowrap;
}

.feedback_trigger__ml79M {
    background: none;
    border-radius: 5px;
    box-shadow: none;
    color: #4d4d4d !important;
    height: 42px;
    line-height: 42px;
    position: relative;
}

.button {
    align-items: center;
    background: var(--btn-bg);
    border: none;
    border-radius: 6px;
    box-shadow: inset 0 0 0 1px var(--btn-border), #1f23280a 0 1px 0 0;
    box-sizing: border-box;
    color: #383838;
    cursor: pointer;
    display: inline-flex;
    font-family: inherit;
    font-size: 13px;
    font-weight: 500;
    height: 34px;
    justify-content: center;
    line-height: 30px;
    outline: none;
    padding: 0 13px;
    text-decoration: none !important;
    transition: all .1s ease-in-out;
    transition-property: background, color;
    white-space: nowrap;
}

.headerProfile {
    background: #343d56;
    border-radius: 50%;
    color: #fff !important;
    font-size: 15px;
    font-weight: 600;
    height: 30px;
    line-height: 30px;
    margin-left: 8px;
    text-align: center;
    width: 30px;
}


button {
    all: unset;
}

.mainContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 50px;
}

.subheader {
    background-color: #fafafa;
    box-shadow: 0 1px #eaeaea;
    left: 0;
    position: -webkit-sticky;
    position: sticky;
    right: 0;
    top: 0;
    z-index: 100;
};

.subheader .wr {
    align-items: center;
    flex-direction: row;
    height: 48px;
    justify-content: space-between;
}

@media (max-width: 600px) {
    .wr {
        padding: 0 15px;
        width: calc(100% - 30px);
    }
}



.subheader__meta {
    align-items: center;
    display: flex;
    white-space: nowrap;
    width: 100%;
}

.accessInfo_wrapper__UFUSz {
    margin-left: 10px;
}

.subheader__meta .parent {
    color: #4d4d4d;
    font-size: 14px;
    margin-right: 20px;
    position: relative;
    text-decoration: none;
}

.subheader__meta h2 {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}

h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}

.titleWr {
    align-items: center;
    display: flex;
}

.statusBadge_badgeWr__Q2jEs {
    align-items: center;
    display: inline-flex;
    justify-content: center;
}

.statusBadge_free__P\+4-r {
    background: #e9ecef;
    color: #495057;
}

.statusBadge_badge__vJJ9X {
    border-radius: 10px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    height: 20px;
    line-height: 20px;
    padding: 0 9px;
    transition: all .1s ease-in-out;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
}

.statusBadge_upgradeBtnWr__8UNwl {
    display: flex;
}

.statusBadge_upgradeBtn__PSSjU {
    align-items: center;
    background: #f8dbff;
    border-radius: 30px;
    color: #7e1980;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    height: 20px;
    justify-content: center;
    line-height: 20px;
    padding: 0 10px;
    text-decoration: none;
    transition: transform .1s ease-in-out;
}

.statusBadge_upgradeBtn__PSSjU svg {
    margin-left: 2px;
}

.accessInfo_wrapper__UFUSz {
    margin-left: 10px;
    align-self: center;
}

.accessInfo_accessBadge__O0UOO {
    align-items: center;
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    color: #24292e;
    cursor: pointer;
    display: flex;
    font: inherit;
    font-weight: 700;
    height: 22px;
    justify-content: center;
    line-height: 22px !important;
    min-width: 30px;
    padding: 2px 6px;
    text-decoration: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.accessInfo_accessBadge__O0UOO svg {
    margin-left: 0 !important;
}

.subheader .actions {
    align-items: center;
    display: flex;
}


.subheader .actions>* {
    margin-left: 10px;
}

.button24 {
    align-items: center;
    background: #fff;
    border: none;
    border-radius: 6px;
    box-shadow: inset 0 0 0 1px #00000017, #1f23280a 0 1px 0 0;
    box-sizing: border-box;
    color: #383838;
    cursor: pointer;
    display: inline-flex;
    font-family: inherit;
    font-size: 13px;
    font-weight: 500;
    height: 34px;
    justify-content: center;
    line-height: 30px;
    outline: none;
    padding: 0 13px;
    text-decoration: none !important;
    transition: all .1s ease-in-out;
    transition-property: background, color;
    white-space: nowrap;
}

.button svg {
    width: 20px;
}

.button--primary, .button--primary.button--loading:hover {
    background: #000;
    box-shadow: inset 0 0 0 1px var(--btn-border), #1f23281a 0 1px 0 0;
    color: #fff;
}

.siteSubtitle {
    color: var(--color-lighter);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 40px;
}

h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}

.siteSettings {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    width: 40%;
}

.siteSetting {
    border-bottom: 1px solid #eee;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
}

.siteSettings__header {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 70px;
    padding: 0 20px;
}

.siteSettings__header svg {
    margin-right: 18px;
    margin-top: 1px;
    opacity: .6;
}

.siteSetting .title {
    font-weight: 500;
    width: 178px;
}

siteSetting .value {
    color: #4d4d4d;
}

.siteSetting .chevron {
    height: 22px;
    margin-left: auto;
    opacity: .25;
}

.siteSettings__header svg {
    margin-right: 18px;
    margin-top: 1px;
    opacity: .6;
}

.site {
    /* flex-direction: row; */
}

.siteSetting__editor {
    padding: 20px;
    width: auto;
    
}

.domain_info__ro60f {
    color: #4d4d4d;
    margin-top: 0;
}
.domain_tableWrapper__72HcU {
    border: 1px solid #eaeaea;
    border-radius: 5px;
}

.domain_table__IEp2C {
    border: none;
    border-collapse: collapse;
    font-size: 13px;
    width: 100%;
}

.domain_table__IEp2C>thead {
    height: 40px;
}
thead {
    display: table-header-group;
    vertical-align: middle;
    unicode-bidi: isolate;
    border-color: inherit;
}

tr {
    display: table-row;
    vertical-align: inherit;
    unicode-bidi: isolate;
    border-color: inherit;
}

.domain_table__IEp2C th:first-child {
    text-align: left;
    width: 30%;
}

.domain_table__IEp2C th {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
}

th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: bold;
    text-align: -internal-center;
    unicode-bidi: isolate;
}
.domain_table__IEp2C th:nth-child(2) {
    width: 47%;
}

tbody {
    display: table-row-group;
    vertical-align: middle;
    unicode-bidi: isolate;
    border-color: inherit;
}

.item_row__SupYn {
    height: 40px;
}

tr {
    display: table-row;
    vertical-align: inherit;
    unicode-bidi: isolate;
    border-color: inherit;
}

.domain_table__IEp2C tr td:first-child {
    padding-left: 15px;
    text-align: left;
}

.domain_table__IEp2C tr td {
    border-top: 1px solid #eaeaea;
    height: 40px;
    padding-right: 2px;
    text-align: center;
    width: 5%;
}

.domain_table__IEp2C tr td:nth-child(2) {
    padding-right: 30px;
    text-align: right;
}

.label_green__moUU2 {
    background-color: #c6fbd6;
    color: #06613e !important;
}

.label_label__C9xzy {
    background-color: #f5f5f5;
    border-radius: 12px;
    display: inline-flex;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    padding: 0 8px;
    white-space: nowrap;
}

.domain_table__IEp2C tr td {
    border-top: 1px solid #eaeaea;
    height: 40px;
    padding-right: 2px;
    text-align: center;
    width: 5%;
}

.domain_table__IEp2C tr td:last-child {
    border-top: 1px solid var(--border);
    padding-right: 10px;
    text-align: right;
    width: 5%;
}

.add_add__FBOKG {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.add_inputWrapper__oWCh- {
    justify-content: space-between;
    margin-right: auto;
    width: 540px;
}

.add_input__7NdKi {
    display: flex;
}

.add_input__7NdKi>ul {
    align-items: center;
    height: 36px;
}

.styles_select__avWeW {
    background-color: var(--secondary-bg);
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 3px;
    position: relative;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;
}

.styles_marker__8anF9 {
    background-color: #fff;
    border-radius: 3px;
    bottom: 3px;
    box-shadow: 0 1px 4px #0000001a;
    position: absolute;
    top: 3px;
    transition: left .15s ease-in-out;
    z-index: 0;
}

.styles_option__7KRJg.styles_active__wy4hK, .styles_option__7KRJg:hover {
    opacity: 1;
}

.styles_option__7KRJg {
    cursor: pointer;
    flex-basis: 0;
    flex-grow: 1;
    opacity: .7;
    position: relative;
    text-align: center;
    transition: opacity .15s ease-in-out;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
}

.styles_option__7KRJg button {
    align-items: center;
    background-color: initial;
    border: 1px solid #0000;
    border-radius: 4px;
    box-shadow: none;
    color: inherit;
    cursor: pointer;
    display: flex;
    font-family: inherit;
    font-size: 12px;
    font-weight: 500;
    height: 22px;
    justify-content: center;
    line-height: 22px;
    margin: 0;
    outline: none;
    padding: 0;
    width: 100%;
}

.styles_option__7KRJg {
    cursor: pointer;
    flex-basis: 0;
    flex-grow: 1;
    opacity: .7;
    position: relative;
    text-align: center;
    transition: opacity .15s ease-in-out;
}

.add_add__FBOKG .add_value__U2U0r {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    margin: 0 20px;
    position: relative;
    width: 250px;
}

.statusBadge_upgradeBtnWr__8UNwl {
    display: flex;
}

.statusBadge_upgradeBtn__PSSjU {
    align-items: center;
    background: #f8dbff;
    border-radius: 30px;
    color: #7e1980;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    height: 20px;
    justify-content: center;
    line-height: 20px;
    padding: 0 10px;
    text-decoration: none;
    transition: transform .1s ease-in-out;
}

.statusBadge_upgradeBtn__PSSjU svg {
    margin-left: 2px;
}

.statusBadge_specification__cu2SL {
    color: #4d4d4d;
    font-size: 13px;
    font-weight: 500;
    margin-left: 4px;
}

.add_actionWrapper__mb8xh, .add_inputWrapper__oWCh- {
    display: flex;
    margin-top: 20px;
}

.add_action__PudvC {
    margin-left: auto;
}

.button--disabled, .button:disabled {
    background-color: #fff !important;
    color: #505050 !important;
    cursor: not-allowed;
    opacity: .6;
}

.add_add__FBOKG .add_value__U2U0r>input:not([type=checkbox]), .add_add__FBOKG .add_value__U2U0r>textarea, .add_add__FBOKG td>input {
    background-color: #f4f4f4;
    border: 1px solid var(--border);
    border-radius: 3px;
    box-shadow: none;
    box-sizing: border-box;
    color: var(--color);
    display: block;
    font-size: 13px;
    height: 34px;
    letter-spacing: 0;
    outline: none;
    padding: 0 9px;
    width: 100%;
}

:where(button,input,select) {
    margin: 0;
}

.add_add__FBOKG .add_value__U2U0r .add_suffix__oF0Gu {
    color: grey;
    font-size: 13px;
    letter-spacing: -.4px;
    line-height: 35px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 0;
}

.siteOverview {
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.siteSummary {
    display: flex;
    flex-grow: 1;
    margin: 0;
    padding: 0;
    width: 67%;
    left: 30px;
    position: relative;
}

.siteSummary li {
    background-color:#fff;
    border-radius: 3px;
    box-shadow: 0 10px 25px #0000000d;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /* flex-grow: 1; */
    list-style: none;
    margin-right: 20px;
    padding: 15px;
    /* width: 33.333%; */
}

.siteSummary li div {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-bottom: 5px;
}

.siteSummary li svg {
    color: #4d4d4d;
    opacity: .8;
}


.siteSummary li div span {
    border-radius: 3px;
    color: #4d4d4d;
    font-size: 11px;
    font-weight: 500;
    margin-left: 5px;
    text-transform: uppercase;
}

.siteSummary li>span {
    display: flex;
    font-size: 15px;
    font-weight: 500;
}

.siteSummary li:last-child {
    margin-right: 0;
}

.siteSummary li.interactive {
    cursor: pointer;
}

.siteSummary li button {
    background-color: initial;
    border: none;
    border: 1px solid #00000017;
    border-radius: 3px;
    color: #383838;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    margin: 0 0 0 auto;
    outline: none;
    padding: 0 6px;
}

.siteSetting__row {
    display: flex;
    margin-bottom: 20px;
}
.siteSetting__row .value {
    flex-shrink: 0;
    position: relative;
    width: 200px;
}

.siteSetting .value {
    color: #4d4d4d;
}

.siteSetting__row label {
    flex-shrink: 0;
    font-size: 13px;
    font-weight: 500;
    line-height: 34px;
    width: 180px;
}

label {
    cursor: default;
}

.siteSetting__row .value input[type=checkbox] {
    margin-right: 5px;
}

:where(button,input,select) {
    margin: 0;
}

.siteSetting__row .info {
    color: #4d4d4d;
    font-size: 13px;
    margin: 0;
}

.siteSetting__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.button--disabled, .button:disabled {
    background-color: #fff !important;
    color: #505050 !important;
    cursor: not-allowed;
    opacity: .6;
}

.button--danger {
    background: #ffecee;
    border-color: #ffecee;
    color: #d42434;
}


.modalContent {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 100px);
    overflow: auto;
    padding: 25px;
}

.modalActions:empty {
    display: none;
}

.modalActions {
    display: flex;
    justify-content: flex-end;
    margin: 20px -5px 0;
}




/* ------------------------------------------------------footer--------------------------------------------------------- */



.footer {
    background: #fafafa;
    border-top: 1px solid #eaeaea;
    display: flex;
    position: relative;
}


@media screen and (max-width: 850px) {
    .footerContent {
        flex-direction: column-reverse;
    }
}


@media screen and (max-width: 1100px) {
    .footerContent {
        flex-direction: column-reverse;
    }
}

.footerContent {
    display: flex;
    flex-grow: 1;
    padding: 30px 0;
}


@media screen and (max-width: 850px) {
    .footerMeta {
        flex-direction: column;
        justify-content: center;
        width: auto;
    }
}

@media screen and (max-width: 1100px) {
    .footerMeta {
        align-items: center;
        border-right: none;
        border-top: 1px solid #eee;
        flex-direction: row;
        justify-content: flex-start;
        padding-right: 0;
        padding-top: 30px;
        width: auto;
    }
}

.footerMeta {
    color: #4d4d4d;
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    width: 20%;
}

@media screen and (max-width: 850px) {
    .footerMeta>span {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 1100px) {
    .footerMeta>span, .footerStatus {
        margin-bottom: 0;
    }
}

.footerMeta>span {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
}

@media screen and (max-width: 1100px) {
    .footerMeta>* {
        margin-right: 20px;
    }
}

@media screen and (max-width: 850px) {
    .footerStatus {
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 1100px) {
    .footerMeta>span, .footerStatus {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 1100px) {
    .footerMeta>* {
        margin-right: 20px;
    }
}


.footerStatus {
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 10px 30px #0000000f;
    display: flex;
    font-size: 13px;
    height: 30px;
    margin-bottom: 15px;
}

.footerStatus a:first-child {
    font-weight: 500;
}

.footerStatus a {
    color: inherit;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    text-decoration: none;
}

.footerMeta .contactLink {
    color: #3350ff;
    text-decoration: none;
}


@media screen and (max-width: 1100px) {
    .footerMeta>* {
        margin-right: 20px;
    }
}


@media screen and (max-width: 1100px) {
    .footerMeta>* {
        margin-right: 20px;
    }
}

@media screen and (max-width: 850px) {
    .footerList {
        flex-direction: column;
    }
}


@media screen and (max-width: 1100px) {
    .footerList {
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 30px;
        width: 100%;
    }
}


.footerList {
    display: flex;
    justify-content: space-evenly;
    width: 80%;
}

@media screen and (max-width: 1100px) {
    .footerList>ul:first-of-type {
        padding: 0;
    }
}


@media screen and (max-width: 850px) {
    .footerList>ul {
        padding-left: 0;
        text-align: center;
    }
}

.footerList>ul {
    list-style: none;
    margin-top: 0;
    font-size: 20px;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;
}

.footerList>ul>li:first-child {
    /* font-weight: 500; */
    margin-bottom: 5px;
    font-size: 13px;

}

.footerList>ul>li:first-child {
    font-weight: 500;
    margin-bottom: 5px;
}

.footerList>ul>li {
    padding-top: 1px;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
}

.footerList>ul>li:first-child {
    font-weight: 500;
    margin-bottom: 5px;
    color:  #4d4d4d;
}

@media screen and (max-width: 1100px) {
    .footerMeta>* {
        margin-right: 20px;
    }
}

.footerIcon {
    height: 26px;
    padding-top: 10px;
}

.footerList>ul>li {
    padding-top: 1px;
}

.footerList>ul>li>a {
    color: #4d4d4d;
    display: block;
    font-weight: 200;
    height: 30px;
    line-height: 30px;
    margin-bottom: 5px;
    text-decoration: none;
}

body {
    --overlay-background: #fff;
    --overlay-shadow: 0 4px 30px #0000001a, 0 4px 10px #00000012;
}

