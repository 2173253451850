@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.landingpage {
  background-image: url("../images/backgroundpng.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: fixed;
  min-height: 100vh;
}

/* .ErrorMassage {
  position: absolute;
  right: 40%;
} */


.um-nav-links {
  margin: 0;
  display: flex;
  padding: 0;
  font-size: 14px;
  list-style: none;
  align-items: center;
}

.um-nav-links>li {
  position: relative;
}

.um-nav-links>li>a {
  font: inherit;
  color: inherit;
  text-decoration: none;
  padding: 10px 10px;
  white-space: nowrap;
}

.inputbar {
  width: 50%;
  height: 36px;
  border: transparent;
  outline: none;
  font-family: Inter;
  background-color: transparent;
  color: #fff;
}

.generate {
  position: relative;
  font-weight: 400;
}

.frame-child {
  width: 22px;
  position: relative;
  height: 22px;
}

/* .generate-parent {
  position: relative;
  border-radius: 8px;
  background: linear-gradient(238.55deg, #653af1 13.89%, #00adb5);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  padding: 6px 16px;
  box-sizing: border-box;
  text-align: left;
  font-size: 16px;
  color: #fff;
  font-family: Inter;
} */

.bar {
  transition: width 0.9s ease;
}

.bar.expanded {
  width: 50%;
}

._41469265 {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  --color-text-secondary: rgb(59, 50, 50);
  padding-left: 50px;
}

._d95f39be {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

._242c4c97 {
  color: rgb(161, 161, 161);
  width: calc(33.333% - 2em);
  height: 460px;
  width: 350px;
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
  padding-top: 1.6em;
  border: 1px solid transparent;
  padding-left: 1.6em;
  border-radius: 10px;
  margin-bottom: 3em;
  padding-right: 1.6em;
  flex-direction: column;
}

.step-1 {
  position: relative;
}

.generate-your-website {
  align-self: stretch;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  color: #9f9f9f;
}

.step-1-parent {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  text-align: left;
  font-size: 24px;
  color: #fff;
  font-family: Inter;
}

._7002e023 {
  position: relative;
  font-size: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  background: linear-gradient(127.24deg, rgba(0, 101, 121, 0.25), rgba(29, 71, 79, 0.25) 47%, rgba(0, 79, 95, 0.25));
}

._298f81cc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

._66958e39 {
  display: flex;
  flex-wrap: wrap;
}

._500f8d70 {
  display: flex;
  padding: 1em;
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

._5c661fe6 {
  color: rgba(255, 255, 255, 1);
  font-size: 1.8em;
  font-weight: 400;
}

._6f97eed2 {
  font-size: 20px;
  color: #9f9f9f;
}

._cc2950fb {
  position: relative;
  font-size: 13px;
  padding-top: 50px;
}

.core-features {
  position: relative;
  font-size: 56px;
  font-weight: 800;
  text-align: center;
  font-family: Inter;
  background: #9f9f9f;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.discover-the-powerful {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  font-family: Inter;
  color: #fff;
  text-align: center;
}

.streamlineinterface-edit-flip-icon {
  width: 30.4px;
  position: relative;
  height: 30.4px;
  overflow: hidden;
  flex-shrink: 0;
}

.smart-design {
  position: relative;
  font-weight: 600;
}

.smart-design-our {
  align-self: stretch;
  position: relative;
  font-size: 16px;
  font-weight: 400;
  color: #9f9f9f;
}

.smart-design-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.streamlineinterface-edit-flip-parent {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  text-align: left;
  font-size: 20px;
  color: #fff;
  font-family: Inter;
}

.faq-tab {
  position: relative;
  border-radius: 16px;
  background: linear-gradient(127.24deg,
      rgba(22, 66, 65, 0.3),
      rgba(18, 54, 55, 0.3) 47%,
      rgba(17, 52, 50, 0.3));
  border: 2px solid #00adb5;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.faq {
  position: relative;
  color: #fff;
}

.faq-parent {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: 24px;
  font-family: Inter;
}

.have-questions-weve {
  position: relative;
  font-size: 14px;
  font-weight: 300;
  font-family: Inter;
  color: #fff;
  text-align: left;
}

.iconoirsearch {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}

.search {
  position: relative;
  font-weight: 500;
  width: 100%;
  background-color: transparent;
  border: none;
  color: #9b9797;
  font-size: 16px;
  outline: none;
}

.iconoirsearch-parent {
  position: relative;
  border-radius: 12px;
  background: linear-gradient(127.24deg, #006579, #1d474f 47%, #004f5f);
  border: 1px solid #00adb5;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  gap: 10px;
  text-align: left;
  font-size: 16px;
  color: #9b9797;
  font-family: Inter;
}

.accordion-- {
  border: none;
  border-radius: 4px;
  background: transparent;
}

.accordion--item {
  border: none;
  border-bottom: 1px solid #0a252d;
}

.accordion--header {
  background: transparent;
  padding: 10px;
  font-weight: 400;
  margin-top: 16px;
  font-family: Inter;
  color: #f1f1f1;
  cursor: pointer;
  font-size: 16px;
}

.accordionheader.active {
  background-color: #0b3138;
}

.accordion--content {
  padding: 10px;
  background: transparent;
  display: none;
  font-size: 14px;
  color: #9f9f9f;
  text-align: left;
  font-weight: 400;
}

.accordion--content.show {
  display: block;
}

._8aaa08d9 {
  color: #fff;
}

._202527cd {
  color: #fff;
  display: flex;
  padding: 20px;
  background: linear-gradient(127.24deg, #006579, #1d474f 47%, #004f5f);
  border: 1px solid #00adb5;
  box-sizing: border-box;
  align-items: center;
  border-radius: 8px;
  justify-content: space-between;
  margin: auto;
}

.get-started-parent {
  border-radius: 8px;
  border: 1px solid #2e7275;
  padding: 8px 16px;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  font-family: Inter;
  background-color: transparent;
  color: #f1f1f1;
}

/* / / ============ Footer ============== / / */

._c4b89fde {
  display: flex;
  padding: 30px 0 60px 0;
  position: relative;
  background: linear-gradient(127.24deg, #006579, #1d474f 47%, #004f5f);
  ;
  border-top: 1px solid rgb(36, 36, 36);
  --color-border-normal: rgb(36, 36, 36);
}

._d1a0a8ea {
  margin: 0;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
}

._0fc50e27 {
  margin-left: 80px;
  margin-bottom: 30px;
}

._91687afc {
  margin: 0;
  padding: 0;
  list-style: none;
}

._e0485177 {
  padding: 4px 0;
  font-size: 14px;
}

._e0485177 a {
  color: #fff;
  font-weight: 400;
  text-decoration: none;
}

._c0e4633f {
  color: #9f9f9f;
  font-weight: 400;
  text-align: left;
}

._a6d0f97b {
  position: relative;
  font-size: 24px;
  font-family: Inter;
  color: #fff;
  text-align: center;
}

.custom-navbar {
  backdrop-filter: blur(2px);
}

.custom-navbar .nav-link {
  color: #fff !important;
  font-weight: 200;
  font-family: Inter;
  font-size: 14px;
  text-decoration: none;
}

.generate-parent {
  position: relative;
  border-radius: 8px;
  background: linear-gradient(127.24deg, #006579, #1d474f 47%, #004f5f);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  padding: 2px 10px;
  box-sizing: border-box;
  font-size: 16px;
  color: #fff;
  font-family: Inter;
}

.custom-navbar .navbar-toggler-icon {
  color: yellow;

}

.build-edit-and {
  margin-top: 20px;
  font-size: 18px;
  font-family: Inter;
  color: #8f8f8f;
  text-align: left;
  font-weight: 400;
}

@keyframes rotateColors {
  0% {
    border-color: rgba(55, 32, 131, 1);
  }

  25% {
    border-color: rgba(131, 32, 55, 1);
  }

  50% {
    border-color: rgba(32, 131, 55, 1);
  }

  75% {
    border-color: rgba(55, 131, 32, 1);
  }

  100% {
    border-color: rgba(32, 55, 131, 1);
  }
}

.bar {
  width: 100%;
  height: 58px;
  position: relative;
  border-radius: 8px;
  background-color: #090b0e;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-top: 20px;
  font-size: 16px;
  color: #363636;
  font-family: DMSans;
  animation: rotateColors 10s linear infinite;
}

.inputbar {
  width: 100%;
  height: 36px;
  border: transparent;
  outline: none;
  background-color: transparent;
  color: #fff;
}

.harness-the-power {
  position: relative;
  font-size: 24px;
  font-weight: 700;
  font-family: Inter;
  color: #fff;
  text-align: center;
}

.trendyfi-web-stands {
  position: relative;
  font-size: 17px;
  font-family: Inter;
  color: #fff;
  text-align: center;
  font-weight: 400;
  display: inline-block;
}

.subscription-plan {
  position: relative;
  font-size: 32px;
  font-weight: 700;
  font-family: Inter;
  color: #fff;
  text-align: center;
}

.cardedit {
  background: linear-gradient(127.24deg, #006579, #1d474f 47%, #004f5f);
  border-radius: 20px;
  height: 300px;
}

@media screen and (max-width: 768px) {
  .cardedit {
    position: relative;
    gap: 20px;
    margin: 20px;
  }
}

.platinum {
  width: 320px;
  position: relative;
  font-size: 20px;
  letter-spacing: 0.05em;
  font-weight: 500;
  font-family: Inter;
  color: #f6e31d;
  text-align: left;
  display: inline-block;
}

.usdt {
  position: relative;
}

.yr {
  position: relative;
  font-size: 20px;
  font-weight: 400;
}

.usdt-parent {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 30px 0px 0px;
  box-sizing: border-box;
  text-align: left;
  font-size: 28px;
  color: #fff;
  font-family: Inter;
  font-weight: 800
}

.vector-icon {
  width: 20px;
  position: relative;
  height: 20px;
}

.create-a-website {
  flex: 1;
  position: relative;
  letter-spacing: 0.05em;
  line-height: 118.5%;
  font-weight: 400;
}

.vector-parent {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  text-align: left;
  font-size: 14px;
  color: #e7f5ff;
  font-family: Inter;
}

.buy-now-button {
  width: 100%;
  position: relative;
  border-radius: 8px;
  background-color: #00adb5;
  height: 36px;
}

.buy-now {
  font-weight: 700;
  position: relative;
  font-size: 18px;
  letter-spacing: 0.05em;
  display: flex;
  font-family: Inter;
  color: #fff;
  text-align: center;
  align-items: center;
  justify-content: center;

}

.inputbar::placeholder {
  color: rgb(170, 170, 170);
  font-size: 16px;
  font-weight: 300;
  opacity: 1;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader {
  border: 8px solid #f3f3f3;
  /* Reduced the loader size */
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 60px;
  /* Reduced the loader size */
  height: 60px;
  /* Reduced the loader size */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}






.fancy {
  --offset: 2.5px;
  background: black;
  border-radius: 50px;
  position: relative;
  height: 56px;
  width: 600px;
  max-width: 100%;
  overflow: hidden;
  border: 1px solid rgb(50, 50, 50);
}

.fancy::before {
  content: "";
  background: conic-gradient(transparent 270deg, #00ADB5, transparent);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 1;
  width: 100%;
  animation: rotate 2s linear infinite;
}

.fancy::after {
  content: "";
  background: inherit;
  border-radius: inherit;
  position: absolute;
  inset: var(--offset);
  height: calc(100% - 2 * var(--offset));
  width: calc(100% - 2 * var(--offset));
}

.fancy input {
  background: transparent;
  color: white;
  font-size: 20px;
  position: absolute;
  inset: 0;
  z-index: 10;
  padding: 1.5rem;
}

.fancy button {
  background: linear-gradient(127.24deg, #006579, #1d474f 47%, #004f5f);
  color: white;
  font-size: 16px;
  position: absolute;
  border-radius: 99px;
  padding: 0px 16px;
  height: 36px;
  right: 10px;
  top: 10px;
  bottom: 0;
  z-index: 10;
  /* / padding: 1.1rem; / */
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) scale(1.4) rotate(0turn);
  }

  to {
    transform: translate(-50%, -50%) scale(1.4) rotate(1turn);
  }
}


.frameChild {
  align-self: stretch;
  position: relative;
  border-radius: 7.08px 7.08px 0px 0px;
  max-width: 100%;
  overflow: hidden;
  height: 119px;
  flex-shrink: 0;
  object-fit: cover;
}

.trendpadIcon {
  width: 221px;
  position: relative;
  border-radius: 6.08px 6.08px 0px 0px;
  height: 45px;
  object-fit: cover;
  margin-top: -20px;
}

.rectangleParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.theAllInOneProduct {
  align-self: stretch;
  position: relative;
  font-weight: 500;
}

.checkoutNow {
  position: relative;
  font-weight: 500;
}

.checkoutNowWrapper {
  border-radius: 8px;
  background-color: rgba(64, 252, 254, 0.3);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  text-align: left;
  font-size: 20px;
  color: #40fcfe;
}

.theAllInOneProductCreatioParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.frameParent {
  width: 'auto';
  position: relative;
  border-radius: 16px;
  background: linear-gradient(127.24deg, rgba(22, 66, 65, 0.3), rgba(18, 54, 55, 0.3) 47%, rgba(17, 52, 50, 0.3));
  border: 2px solid #175757;
  box-sizing: border-box;
  height: 327.2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  gap: 34px;
  text-align: center;
  font-size: 16px;
  color: #9f9f9f;
  font-family: 'DM Sans';
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&display=swap');

body {
  margin: 0;
  line-height: normal;
}


.exploreTrendProducts {
  position: relative;
  font-weight: 800;
}

.products {
  position: relative;
  font-size: 40px;
  color: #fff;
  text-align: left;
}

.exploreTrendProductsParent {
  position: absolute;
  top: 91px;
  left: calc(50% - 416px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.frameChild {
  align-self: stretch;
  position: relative;
  border-radius: 7.08px 7.08px 0px 0px;
  max-width: 100%;
  overflow: hidden;
  height: 119px;
  flex-shrink: 0;
  object-fit: cover;
}

.trendpadIcon {
  width: 221px;
  position: relative;
  border-radius: 6.08px 6.08px 0px 0px;
  height: 45px;
  object-fit: cover;
  margin-top: -20px;
}

.rectangleParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.theAllInOneProduct {
  align-self: stretch;
  position: relative;
  font-weight: 500;
}

.checkoutNow {
  position: relative;
  font-weight: 500;
}

.checkoutNowWrapper {
  border-radius: 8px;
  background-color: rgba(64, 252, 254, 0.3);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  text-align: left;
  font-size: 20px;
  color: #40fcfe;
}

.theAllInOneProductCreatioParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.frameContainer {
  /* width: 383px; */
  border-radius: 16px;
  background: linear-gradient(127.24deg, rgba(22, 66, 65, 0.3), rgba(18, 54, 55, 0.3) 47%, rgba(17, 52, 50, 0.3));
  border: 2px solid #175757;
  box-sizing: border-box;
  /* height: 327.2px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 32px; */
  gap: 34px;
}

.frameItem {
  width: 348px;
  position: relative;
  border-radius: 8px 8px 0px 0px;
  height: 119px;
  object-fit: cover;
}

.trendpadIcon1 {
  width: 253px;
  position: relative;
  border-radius: 8px 8px 0px 0px;
  height: 52px;
  object-fit: cover;
  margin-top: -20px;
}

.rectangleGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.trendadsIcon {
  width: 229px;
  position: relative;
  border-radius: 8px 8px 0px 0px;
  height: 52px;
  object-fit: cover;
  margin-top: -20px;
}

.frameParent1 {
  width: 383px;
  border-radius: 16px;
  background: linear-gradient(127.24deg, rgba(22, 66, 65, 0.3), rgba(18, 54, 55, 0.3) 47%, rgba(17, 52, 50, 0.3));
  border: 2px solid #175757;
  box-sizing: border-box;
  height: 327px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  gap: 34px;
}

.trenddxlogoIcon {
  width: 227px;
  position: relative;
  border-radius: 8px 8px 0px 0px;
  height: 42px;
  object-fit: cover;
  margin-top: -20px;
}

.checkoutNowWrapper1 {
  border-radius: 8px;
  background-color: #175757;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  text-align: left;
  font-size: 20px;
  color: #40fcfe;
}

.frameGroup {
  position: relative;
  top: 101px;
  /* left: calc(50% - 794px); */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  font-size: 16px;
  font-family: 'DM Sans';
  width: 400px;
  height: 400px;

}

.frameParent {
  width: 100%;
  position: relative;
  height: 823px;
  overflow: hidden;
  text-align: center;
  font-size: 72px;
  color: #9f9f9f;
  font-family: Inter;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&display=swap');

body {
  margin: 0;
  line-height: normal;
}


.product-containerproduct {
  width: 100%;
  padding: 9rem 1rem;
}

.product-containerproduct {
  width: 100%;
  margin: 2rem auto;
  padding: 8rem 0;
}

.product-containerproduct {
  width: 100%;
  padding: 9rem 1rem;
}

.product-heading {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
}

.product-heading {
  font-size: 2rem;
}

.product-heading {
  font-size: 3rem;
}

.product-card-containerproduct {
  width: 100%;
  max-width: 18rem;
  padding: 1.5rem 1rem;
  background: linear-gradient(127.24deg, rgba(22, 66, 65, 0.3), rgba(18, 54, 55, 0.3) 47%, rgba(17, 52, 50, 0.3));
  border: 1px solid #175757;
  border-radius: 8px;
  box-shadow: 0px 5px 22.8px 0px rgba(46, 46, 46, 0.33);
}

.product-card-logo {
  margin: -1.5rem 0 0 1rem;
}


.product-button {
  padding: 0.5rem 1.25rem;
  background-color: rgba(64, 252, 254, 0.3);
  border-radius: 0.5rem;
  font-size: 1.2rem;
  color: #40fcfe;
  text-decoration: none;
}

.product-card-text {
  text-align: center;
  color: #9f9f9f;
  font-weight: 500;
  margin: 1rem 0;
}


.rectangleDiv {
  /* width: 100%; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 35px; */
  /* background: linear-gradient(127.24deg, #006579, #1d474f 47%, #004f5f); */
  /* height: 624px; */
}

.removebg1Icon {
  /* width: 100%; */
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 428.2px;
  object-fit: contain;
}

;



.Seletectemplateheading {
  color: #f3e6e6;
  font-size: 48px;
  font-weight: 600px;
  font-family: "Inter";
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.SelectTemplate{
  display:flex;
  justify-content:center;
  width:100%;
  height:100%;
  align-items:center;
  
  gap:40px;
}

@media screen and (max-width: 1024px) {
  
  
  .SelectTemplate{
    display:flex;
    justify-content:center;
    width:100%;
    height:100%;
    align-items:center;
    flex-direction: column;
    gap:40px;
  }
}


.LogoImage{
  position: absolute;
   right: 0px;
    top: 0px;
    width: 500px;
}

@media  only screen and (max-width: 1000px){
  .LogoImage{
    position: relative;
     right: 0px;
      top: 0px;
      width: 100%;
  }
}