@import url("https://fonts.googleapis.com/css2?family=Itim:wght@400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


/* section {
  background-color: #fe9c34;
} */
section .T2firstsection {
  height: auto;
  padding-top: 2em;
  /* background-image: url("./images/wildmainbg.png"); */
  background-repeat: no-repeat;
  background-size: cover;
}
/* commnon start */

.T2navarticle,
.T2mainarticle,
.T2secondsection main,
.T2Tokenomics,
.T2Tokenomicssection main,
.T2footersection {
  width: 90%;
  margin: auto;
}
.T2mainarticle,
.T2nav,
.T2logocontainer,
.T2btns,
.T2threelinks,
.T2threebtn,
.T2secondsection main,
.T2Tokenomicssection main,
.T2Tokenomics {
  display: flex;
}
.T2nav,
.T2logocontainer,
.T2threelinks,
.T2secondsection main,
.T2Tokenomics {
  align-items: center;
}
.T2icons,
.T2menu {
  display: flex;
  flex-wrap: wrap;
}

/* commnon End */

/*  navbar start */
.T2navarticle {
  width: 90%;
  border-radius: 8px;
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.8);
  padding: 10px;
  overflow: hidden;
}
.T2nav {
  justify-content: space-between;
  height: 60px;
}
.T2logo {
  width: 50px;
  height: 60px;
  object-fit: cover;
}
.T2heading {
  margin-left: 15px;
  font-size: 35px;
  font-family: Itim;
  color: #fff;
  text-shadow: 2px 0 0 #000, 0 2px 0 #000, -2px 0 0 #000, 0 -2px 0 #000;
}
.T2menu {
  list-style: none;
  gap: 20px;
}
.T2menuitem a {
  text-decoration: none;
  color: #000;
  font-size: 20px;
  padding: 5px 10px;
}
.T2menuitem a:hover {
  color: #007bff;
}
.T2icons {
  gap: 15px;
}
.T2icon {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
/*  navbar End */

/* main coin start */
.T2mainarticle aside {
  width: 50%;
  margin-top: 4.5em;
}
.T2mainleft .heading {
  margin-left: 1.8em;
}
.T2heading1 {
  font-weight: 400;
  font-size: 72px;
  font-family: Itim;

  text-shadow: 2px 0 0 #000, 0 2px 0 #000, -2px 0 0 #000, 0 -2px 0 #000;
}
.T2mainleft .T2heading6 {
  font-size: 32px;
  font-family: Itim;

  text-shadow: 1.5px 0 0 #000, 0 1.5px 0 #000, -1.5px 0 0 #000, 0 -1.5px 0 #000;
}
.T2mainleft img {
  width: 80%;
  height: 60%;
}

.T2welcometexttxtcontainer {
  width: 100%;
}
.T2welcometext {
  font-size: 40px;
  font-family: Itim;
  text-shadow: 1.5px 0 0 #000, 0 1.5px 0 #000, -1.5px 0 0 #000, 0 -1.5px 0 #000;
  color: #fff;
  line-height: 50px;
}
.T2g {
  color: #ffcd4e;
}
.T2meme {
  display: inline-block;
  margin-top: 0.3em;
}
.T2btns {
  margin-top: 2em;
  gap: 0px 20px;
}
.T2link {
  box-shadow: 6px 6px 0px #000;
  border-radius: 5px;
  border: 1px solid #000;
  height: 45px;
  background-color: #fff;
}
.T2link a {
  text-decoration: none;
  font-family: Itim;
  padding: 6px 12px;
  font-size: 16px;
}

.T2CA {
  margin-top: 1.5em;
}
.T2contractlabel {
  font-size: 28px;
  font-family: Itim;

  word-break: break-all;
  text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
    0 -1px 0 #181818;
}
.T2threebtn {
  gap: 0px 20px;
  margin-top: 2em;
  /* flex-wrap: wrap; */
}
.T2threelinks {
  font-family: Itim;
}
.T2threelinks img {
  height: 32px;
  width: 32px;
}

.T2threebtn #link1 {
  box-shadow: 6px 6px 0px #8c3483;
}
.T2threebtn #link2 {
  box-shadow: 6px 6px 0px #1a48b5;
}
.T2threebtn #link3 {
  box-shadow: 6px 6px 0px #507125;
}
/* main coin End */

/* About start */
.T2secondsection,
.T2Tokenomicssection,
.T2howtobuysection {
  height: auto;
  margin: auto;
  padding-top: 2em;
  /* background-image: url("./images/wildmainbg.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
}
.T2secondsection main {
  padding: 5em 0;
}
.T2secondsection .T2secondleft {
  width: 60%;
}
.T2secondsection .T2secondright {
  width: 40%;
}
.T2abouttext {
  width: 80%;
  margin: auto;
}
.T2headingabout {
  text-align: center;
}
.T2secondright img {
  width: 75%;
}
.T2secondbtn {
  margin-left: 5em;
}
/* Tokenomics start */
.T2Tokenomicsleft,
.T2Tokenomicsright {
  width: 50%;
}
.T2Tokenomics {
  padding-top: 4em;
  justify-content: center;
  gap: 0px 10px;
}
.T2Tokenomicssection main {
  margin-top: 5em;
}
.T2heading3 {
  width: 85%;
  font-size: 40px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px 0px;

  font-family: Itim;
}
.T2supplystatement {
  text-shadow: 1.5px 0 0 #000, 0 1.5px 0 #000, -1.5px 0 0 #000, 0 -1.5px 0 #000;
}
.T2threelinks {
  gap: 0px 10px;
}
#T2Tokenomicsbtn {
  margin-bottom: 1em;
  margin-top: 4em;
}
/* How to Buy start */
.T2howtobuytext {
  display: flex;
  flex-direction: column;
  gap: 1em 0;
  text-align: center;
  max-width: 840px;
  margin-top: 1em;
}
/*  Footer start  */
.T2footersection,
.T2howtobuycontent {
  height: auto;
  padding: 5em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.T2footerheading {
  font-family: Itim;
  font-size: 35px;
  text-align: center;
  text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
    0 -1px 0 #181818;
}

.T2text2 {
  margin-top: 1em;
}
/* Footer End */

@media (max-width: 1010px) {
  .T2nav {
    flex-direction: column;
    height: auto;
    gap: 1em 0;
  }
  .T2logocontainer {
    justify-content: center;
  }
  .T2logocontainer {
    text-align: center;
  }
}
@media (max-width: 992px) {
  .T2mainarticle,
  .T2secondsection main,
  .T2Tokenomicssection main {
    flex-direction: column;
  }
  .T2mainarticle,
  .T2mainarticle aside {
    align-items: center;
    justify-content: center;
  }
  .T2mainarticle aside,
  .T2Tokenomicsleft,
  .T2Tokenomicsleft img,
  .T2secondsection .T2secondright,
  .T2secondsection .T2secondleft,
  .T2abouttext,
  .T2Tokenomicsright,.heading-3  {
    width: 100%;
  }
  .T2threebtn,
  .T2secondbtn {
    gap: 1em 1em;
    align-items: center;
    justify-content: center;
  }
  .T2secondbtn,
  .T2mainleft .heading,
  .T2heading3 {
    margin: auto;
  }
  .T2heading1,
  .T2mainleft .heading6,
  .T2abouttext,
  .T2welcometexttxtcontainer,
  .T2heading3 {
    text-align: center;
  }
  .T2mainarticle aside,
  .T2secondright {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  #T2Tokenomicsbtn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .T2mainleft img,
  .T2contractlabel,
  .T2heading3 {
    width: 100%;
  }
}
@media (max-width: 450px) {
  .T2heading1 {
    font-size: 14vw;
  }
  .T2heading3,
  .T2welcometext,
  .T2footerheading {
    font-size: 10vw;
  }
  .T2contractlabel {
    font-size: 6vw;
  }
  .T2howtobuytext,
  .T2Tokenomics,
  .T2Tokenomicssection,
  .T2secondsection main,
  .T2footersection,
  .T2howtobuycontent,
  .T2secondright {
    padding: 0.5em 0.5em;
  }
  .T2Tokenomicssection main,
  .T2mainarticle aside {
    margin: auto;
  }
  .T2link a {
    font-size: 2.6vw;
  }
}
