@import url("https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@100;300;400;900&display=swap");

.body {
  margin: 0px;
  overflow-x: hidden;
  min-height: 768px !important;
}
.mememenu-memecoin {
  position: relative;
  background-position: center;
  text-align: center;
  background-repeat: no-repeat;
  background-position-y: top;
  /* width: 100vw; */
  height: 200px;
  background-size: cover;
  z-index: 9;
}
.mememenu-inner {
  margin: auto;
  overflow: visible;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mememenu-item {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  margin-right: 10px;
  padding: 15px 15px;
  transition: all 300ms ease-out;
}
.mememenu-item  {
  color: white;
  font-weight: 900;
  text-decoration: none;

  cursor: pointer;
}
.logo {
  filter: drop-shadow(4px 4px 0px rgba(1, 1, 1, 0.2));
}
#hero {
  margin-top: -15px;
  padding-top: 300px;
  color: white;
  /* width: 100vw; */
  background-size: cover;
  height: calc(100vh - 200px);
  position: relative;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (min-width: 701px) and (max-width: 1920px) {
  #hero,
  #vision
   {
    min-height: calc(1080px - 200px);

    overflow: hidden;
  }
}
#hero::before {
  content: "";

  left: 0px;
  right: 0px;
  width: 100vw;
  background-size: 100% 100%;
  background-repeat: repeat-x;
  height: 100%;
  top: 0;
  position: absolute;
  bottom: 57%;
}

#hero .section-inner {
  text-align: center;
}
.hero-animation {
  position: absolute;
  top: -4% !important;
  max-width: 250px;
  animation: herofly 20s linear infinite;
}

.boomer-cartoon {
  z-index: 4;
}
@media screen and (min-width: 701px) and (max-width: 1920px) {
  .hero-animation {
    max-width: 250px !important;
  }
}
@keyframes herofly {
  0% {
    transform: translateX(-30vw) scaleX(1);
  }

  50% {
    transform: translateX(130vw) scaleX(1);
  }
  51% {
    transform: translateX(130vw) scaleX(-1);
  }
  100% {
    transform: translateX(-30vw) scaleX(-1);
  }
}

#hero .header-container {
  /* background: #023cbaac; */
  /* mask-image: url(../images/mask.svg); */
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  display: inline-block;
  margin-bottom: 50px;
  padding: 90px;
  text-align: center;
  margin-top: -18vh;
  z-index: 20;
  position: relative;
}
#hero h2 {
  font-size: 1.5em;
  margin: 0px;
}
h1 {
  font-weight: 900;
  color: white;
  font-size: 4em;
  margin-top: 0px;
  margin-bottom: 0px;
}
.boomer-cig {
  position: absolute;
  max-width: 480px;
  right: 8vw;
  bottom: 1.2vh;
}
.boomer-chair {
  position: absolute;
  max-width: 450px;
  left: 12vw;
  bottom: 1.2vh;
}

.boomer-cartoon {
  z-index: 4;
}
@media screen and (min-width: 701px) and (max-width: 1920px) {
  .boomer-cig {
    max-width: 400px !important;
    right: 2vw;
  }
}

@media screen and (min-width: 701px) and (max-width: 1920px) {
  .boomer-chair {
    max-width: 380px !important;
    left: 2vw !important;
  }
}

.arrow-memecoin {
  position: absolute;
  bottom: 9vh;
  width: 70px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.arrow-memecoin img {
  animation: swivel 1s ease infinite;
}
@keyframes swivel {
  0% {
    transform: translateY(20px);
  }

  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(20px);
  }
}
.memebutton-container {
  margin: auto;
  z-index: 800;
  position: relative;
}
.memebutton {
  transition: all 300ms ease-out;
}
.memebutton:hover {
  transform: scale(1.05) !important;
}

.memebutton-primary {
  background: #023cba;
  color: white;
}
.memebutton {
  padding: 15px 15px;
  margin-left: 10px;
  margin-right: 10px;

  min-width: 175px;
  display: inline-block;
  font-weight: 800 !important;
}
a {
  text-decoration: none;
}
a.memebutton.memebutton-secondary {
  text-align: center;
}

.memebutton-secondary {
  background: white;
  color: #023cba;
}

/* .section:not(#hero) {
  min-height: 110vh;
  position: relative;
} */

#vision {
  /* background-color: #0452ff; */
  background-repeat: repeat;
  background-size: 350px;
  padding-bottom: 30vh;
  position: relative;
  /* background-attachment: fixed; */
}
#vision {
  /* background: #0452ff; */
}
#vision::before,
#howtobuy::before {
  content: "";
  display: block;
  width: 100%;
  top: 0px;
 
  height: 100%;
  position: absolute;
  z-index: 0;
}
#vision .section-inner {
  z-index: 1;
  /* position: relative; */
  text-align: center;
  
}
.header-container {
  text-align: center;
  padding-top: 5vh;
}
.section.blue h1,
.section.blue p {
  color: white ;
}
#vision p {
  max-width: 550px;
  margin: auto;
  margin-top: 40px;
  font-family: "Londrina Solid";
  font-size: 24px;
}
@media screen and (min-width: 701px) and (max-width: 1200px) {
  #vision {
    min-height: 105vh !important;
  }
}
@media screen and (min-width: 701px) and (max-width: 1920px) {
  #vision {
    padding-bottom: 250px !important;
  }
}
@media screen and (min-width: 701px) and (max-width: 1920px) {
  #hero,
  #vision {
    min-height: calc(1080px - 200px);
  }
}

@media screen and (min-width: 701px) and (max-width: 1200px) {
  .section:not(#hero):not(#vision) {
    
    min-height: auto !important;
  }
}

/* .section:not(#hero) {
  min-height: 110vh;
  position: relative;
} */
/* .section.white {
  padding-bottom: 15vh;
} */
.section.white h1,
.section.white p {
  color: #fafafc ;
}
.section.white h1 {
  letter-spacing: 1.1px;
  font-family: "Londrina Solid";
}

.heading-3 {
  font-size: 56px !important;
  color: #0205ba;
  letter-spacing: 1.1px;
  font-family: "Londrina Solid";
  top: 90px;
  position: relative;
}

.get-started-with {
  position: relative;
  font-size: 16px;
  letter-spacing: 0.14px;
  line-height: 26px;
  font-weight: 500;
  text-align: center;
}

.heading-3-legendary-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 29px;
}



.div1 {
  position: relative;
  letter-spacing: 0.14px;
  line-height: 16px;
  font-weight: 600;
  color: #fff;
}

.wrapper {
  position: absolute;
  top: 61px;
  left: 0px;
  border-radius: 999px;
  background-color: #000000;
  width: 64px;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
}

.frame-item {
  position: absolute;
  top: 0px;
  left: 32px;
  max-height: 100%;
  width: 100%;
}

.group-parent {
  width: 64px;
  position: relative;
  height: 125px;
  z-index: 1;
}

.install-a-chrome {
  width: 206px;
  position: relative;
  font-size: 16px;
  line-height: 26px;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.step-1-parent-memecoin {
  width: 226px;
  border-radius: 16px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 44px 20px;
  box-sizing: border-box;
  gap: 28px;
  z-index: 0;
  margin-top: -28px;
  font-size: 20px;
  overflow: hidden;
}

.div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  top:-5rem;
}

.mint-mlon-eusk {
  margin: 0;
}

.mint-mlon-eusk-container1 {
  width: 100%;
}

.mint-mlon-eusk-container {
  width: 206px;
  position: relative;
  font-size: 16px;
  line-height: 26px;
  display: flex;
  color: #631f1f;
  align-items: center;
  text-align: center;
}

.optional {
  font-size: 14px;
}

.step-5-optional-container {
  position: relative;
  letter-spacing: 0.14px;
  line-height: 26px;
}

.step-5-optional-parent {
  width: 226px;
  height: 220px;
  border-radius: 16px;
  background: linear-gradient(180deg, #023cba, #516faf);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 44px 20px;
  box-sizing: border-box;
  gap: 28px;
  z-index: 0;
  margin-top: -28px;
  font-size: 20px;
}

.parent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 56px;
  text-align: center;
  font-size: 36px;
  flex-wrap: wrap;
}

.frame-parent {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 87px;
  text-align: left;
  font-size: 48px;
  color: #fff;
  font-family: Montserrat;
  top: -90px;
}

@media (max-width: 528px) {
  .heading-3 {
    font-size: 24px;
    line-height: 32px;
  }

  .step-1-parent,
  .step-5-optional-parent {
    padding: 20px;
    font-size: 16px;
  }

  .install-a-chrome {
    width: auto;
    font-size: 14px;
  }

  .parent {
    flex-direction: column;
    gap: 20px;
  }

  .frame-child,
  .frame-item {
    position: relative;
    left: 0;
  }

  .wrapper {
    top: 0;
  }
}

.white-logosvg-icon {
  position: absolute;
  top: 45px;
  left: calc(50% - 580.01px);
  width: 180px;
  height: 40px;
  overflow: hidden;
}
.Newwhite-logosvg-icon {
  position: absolute;
  top: 45px;
  left:10px;
  width: 180px;
  height: 40px;
  overflow: hidden;
}
.meme-was-made {
  position: absolute;
  top: 110px;
  left: 240px;
  line-height: 110%;
  display: inline-block;
  width: 755px;
}
.Newmeme-was-made {
  position: absolute;
  top: 110px;
  left: 60px;
  line-height: 110%;
  display: inline-block;
  width: 755px;
}
.link-boomer-on-base-social-m {
  position: absolute;
  top: 48px;
  left: calc(50% + 224px);
  width: 50px;
  height: 50px;
}
.Newlink-boomer-on-base-social-m {
  position: absolute;
  top: 48px;
  left: calc(50% + 94px);
  width: 50px;
  height: 50px;
}
.link-boomer-on-base-social-m1 {
  position: absolute;
  top: 48px;
  left: calc(50% + 294px);
  width: 50px;
  height: 50px;
}
.Newlink-boomer-on-base-social-m1 {
  position: absolute;
  top: 48px;
  left: calc(50% + 154px);
  width: 50px;
  height: 50px;
}
.link-boomer-on-base-social-m2 {
  position: absolute;
  top: 48px;
  left: calc(50% + 364px);
  width: 50px;
  height: 50px;
}
.Newlink-boomer-on-base-social-m2 {
  position: absolute;
  top: 48px;
  left: calc(50% + 214px);
  width: 50px;
  height: 50px;
}

.link-boomer-on-base-social-m3 {
  position: absolute;
  top: 48px;
  left: calc(50% + 434px);
  width: 50px;
  height: 50px;
}
.Newlink-boomer-on-base-social-m3 {
  position: absolute;
  top: 48px;
  left: calc(50% + 274px);
  width: 50px;
  height: 50px;
}
.link-boomer-on-base-social-m4 {
  position: absolute;
  top: 48px;
  left: calc(50% + 504px);
  width: 50px;
  height: 50px;
}
.Newlink-boomer-on-base-social-m4 {
  position: absolute;
  top: 48px;
  left: calc(50% + 334px);
  width: 50px;
  height: 50px;
}
.link-boomer-on-base-social-m5 {
  position: absolute;
  top: 48px;
  left: calc(50% + 574px);
  width: 50px;
  height: 50px;
}
.Newlink-boomer-on-base-social-m5 {
  position: absolute;
  top: 48px;
  left: calc(50% + 394px);
  width: 50px;
  height: 50px;
}
.footermeme {
  /* width: 100%; */
  width: auto;
  position: relative;
  /* margin-top: 200px; */
  /* background-color: #023cba; */
  height: 300px;
  text-align: center;
  font-size: 28px;
  color: #fff;
  font-family: "Londrina Solid";
  justify-content: center;
  align-items: center;
  /* top: -90px; */
  /* overflow-y: hidden; */
}
/* @media screen and (min-width: 701px) and (max-width: 1367px) {
  .meme-was-made {
  position: absolute;
  top: 110px;
  left: 60px;
  line-height: 160%;
  display: inline-block;
  width: 755px;
}
  .white-logosvg-icon {
  position: absolute;
  top: 45px;
  left: 20px;
  width: 180px;
  height: 40px;
  overflow: hidden;
}

  .link-boomer-on-base-social-m {
   position: relative;
   left: 20px;
   top: -10px;
  }
  .link-boomer-on-base-social-m1 {
    position: relative;
   left: 20px;
   top: -10px
  }
  .link-boomer-on-base-social-m2 {
    position: relative;
    left: 20px;
    top: -10px
  }
  .link-boomer-on-base-social-m3 {
    position: relative;
   left: 20px;
   top: -10px
  }
  .link-boomer-on-base-social-m4 {
    position: relative;
    left: 20px;
    top: -10px
  }
  .link-boomer-on-base-social-m5 {
    position: relative;
    left: 20px;
    top: -10px
  }
  
} */


.frame-container {
  padding: 5vw;
  /* width: 90vw; */
  /* background: url(../images/tokonmicsbg.svg); */
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
   object-fit: cover;
  color: white !important;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 40px !important;
  align-items: center;
  gap: 80px;
  background-size: cover;
  padding-top: 150px;
}
.token-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  text-align: center;
}
.token-grid-item{

  text-align: center;
  font-weight: 500;
  font-size: 24px;
}
.material-symbols-outlined{
  font-size: 28px;
  display: block;
  text-align: center;
  margin: auto;
}
.token-grid span,
.token-grid .textarea {
  font-weight: 300;
  margin-top: 15px;
  display: inline-block;
  width: 246px;
}



.textarea {
  resize: none;

  color: white;
  font-size: 22px;
  border-radius: 15px;
  border: none;
  max-width: 250px;
  word-wrap: break-word;
}
.boomer-coin-text {
  Max-width: 24vw;
  margin: auto;
  text-align: center;
  padding-bottom: 30px ! Important;
  color: white ;
  line-height: 1.3em;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.animate-number{
  font-size: 24px;
  font-weight: 600 !important;
}

/* .container {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 10px;
} */