.header_header__-wvTX {
  align-items: center;
  display: flex;
}
.header_section__sW6qw {
  align-items: center;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
  color: #fff;
  display: flex;
  height: 36px;
  overflow: hidden;
  padding: 0 2px;
  z-index: 0;

}
.header_section__sW6qw > button::after {
  border-radius: 4px;
  content: "";
  inset: 4px 2px;
  position: absolute;
  z-index: -1;
}


.header_section__sW6qw > button:hover::after {
  background-color: #f2f2f3;
 
}


button.header_menu__aNf4u {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  margin: 0;
  padding: 0 15px 0 0;
}
.header_section__sW6qw > button.header_active__SotvQ {
  color: black;
  text-shadow: 0 1px 1px #0000001a;
}
.header_section__sW6qw > button {
  all: unset;
  color: inherit;
  cursor: pointer;
  display: block;
  font: inherit;
  font-size: 13px;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
  padding: 0 12px;
  position: relative;
}

.tryout_tryout__UoPE2 {
  align-items: center;
  background: #f5f5f5;
  border-radius: 4px;
  color: #4d4d4d;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  margin: 0 auto;
  padding: 4px 10px 4px 6px;
}
.tryout_tryout__UoPE2 span {
  align-items: center;
  color: #3350ff;
  display: flex;
  margin-left: 4px;
  margin-right: 9px;
}
.tryout_tryout__UoPE2 a {
  margin: 0 8px;
}

.button--small {
  font-size: 13px;
  height: 28px;
  line-height: 28px;
  padding: 0 8px;
}
.button {
  align-items: center;
  background: #fff;
  border: none;
  border-radius: 6px;
  box-shadow: inset 0 0 0 1px var(--btn-border),
    rgba(31, 35, 40, 0.04) 0 1px 0 0;
  box-sizing: border-box;
  color: #383838;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 13px;
  text-decoration: none !important;
  transition: all 0.1s ease-in-out;
  transition-property: background, color;
  white-space: nowrap;
}
.tryout_tryout__UoPE2 a {
  margin: 0 8px;
}

.button--primary,
.button--primary.button--loading:hover {
  /* background: #000; */
  box-shadow: inset 0 0 0 1px var(--btn-border), rgba(31, 35, 40, 0.1) 0 1px 0 0;
  color: #fff;
}
.button--small {
  font-size: 13px;
  height: 28px;
  line-height: 28px;
  padding: 0 8px;
}
.header_actions__CVfcP {
  align-items: center;
  display: flex;
  margin-left: auto;
}
.header_actions__CVfcP > button {
  all: unset;
  color: #4d4d4d;
  cursor: pointer;
  font-weight: 500;
  height: 36px;
  padding: 0 10px;
  position: relative;
}
.header_section__sW6qw {
  align-items: center;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  color: #000;
  display: flex;
  height: 36px;
  overflow: hidden;
  padding: 0 2px;
  z-index: 0;
}

@media screen and (max-width: 933px) {
    .tryout_tryout__UoPE2{
     display: none;
    }
};
