.toolbar_container__7VQRL {
    align-items: center;
    background-color:#fff;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    flex-shrink: 0;
    height: 38px;
    padding: 0 5px 0 10px;
   /* position: fixed; */
}

.toolbar_addressBar__DbP9z {
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 14px;
    display: flex;
    flex-grow: 1;
    height: 28px;
    margin: 0 10px;
    min-width: 0;
    padding: 0 10px;
}
.toolbar_domain__Ie5uZ {
    color: #4d4d4d;
    display: block;
    font-size: 13px;
    font-weight: 500;
    margin-left: 7px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.size_wrapper__XQ9F- {
    all: unset;
    align-items: center;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    height: 28px;
    justify-content: center;
    overflow: hidden;
    width: 40px;
}