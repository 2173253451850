.subheader {
    background:#fafafa;
    box-shadow: 0 1px #eaeaea;
    left: 0;
    position: -webkit-sticky;
    position: sticky;
    right: 0;
    top: 0;
    z-index: 100;
}

.subheader .wr {
    align-items: center;
    flex-direction: row;
    height: 48px;
    justify-content: space-between;
}

.wr {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1600px;
    padding: 0 30px;
    width: calc(100% - 60px);
}

.subheader__meta {
    align-items: center;
    display: flex;
    white-space: nowrap;
    width: 100%;
    justify-content: space-between;
}

.subheaderActions {
    display: flex;
    flex-wrap: nowrap;
}

.subheaderSearch {
    -moz-osx-font-smoothing: initial;
    -webkit-font-smoothing: initial;
    background: #fff;
    background: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    color: #24292e;
    font-size: 13px;
    height: 34px;
    margin-right: 10px;
    outline: none;
    padding: 0 10px;
    width: 200px;
}

.button {
    align-items: center;
    background: #fff;
    border: none;
    border-radius: 6px;
    box-shadow: inset 0 0 0 1px#00000017, #1f23280a 0 1px 0 0;
    box-sizing: border-box;
    color: #383838;
    cursor: pointer;
    display: inline-flex;
    font-family: inherit;
    font-size: 13px;
    font-weight: 500;
    height: 34px;
    justify-content: center;
    line-height: 30px;
    outline: none;
    padding: 0 13px;
    text-decoration: none !important;
    transition: all .1s ease-in-out;
    transition-property: background, color;
    white-space: nowrap;
}

.button--primary, .button--primary.button--loading:hover {
    background: #000;
    box-shadow: inset 0 0 0 1px var(--btn-border), #1f23281a 0 1px 0 0;
    color: #fff;
}

.subheader__meta h2 {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}

h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}


.footer {
    background: var(--bg-01);
    border-top: 1px solid var(--border);
    display: flex;
    position: relative;
    min-height: 100%;
}

.wr {
    display: flex;
    /* flex-direction: column; */
    flex-wrap: nowrap;
    margin: 0 auto;
    max-width: 1600px;
    padding: 0 30px;
    /* width: calc(100% - 60px); */
}

@media screen and (max-width: 850px) {
    .footerMeta {
        flex-direction: column;
        justify-content: center;
        width: auto;
    }
}

@media screen and (max-width: 1100px) {
    .footerMeta {
        align-items: center;
        border-right: none;
        border-top: 1px solid #eee;
        flex-direction: row;
        justify-content: flex-start;
        padding-right: 0;
        padding-top: 30px;
        width: auto;
    }
}


.footerMeta {
    color:#4d4d4d;
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    width: 20%;
}

@media screen and (max-width: 850px) {
    .footerContent {
        flex-direction: column-reverse;
    }
}

@media screen and (max-width: 850px) {
    .footerMeta>span {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 1100px) {
    .footerMeta>span, .footerStatus {
        margin-bottom: 0;
    }
}

.footerMeta>span {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
}

@media screen and (max-width: 1100px) {
    .footerMeta>* {
        margin-right: 20px;
    }
}

@media screen and (max-width: 850px) {
    .footerStatus {
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 1100px) {
    .footerMeta>span, .footerStatus {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 1100px) {
    .footerMeta>* {
        margin-right: 20px;
    }
}

.footerStatus {
    align-items: center;
    background-color:#fff;
    border-radius: 4px;
    box-shadow: 0 10px 30px #0000000f;
    display: flex;
    font-size: 13px;
    height: 30px;
    margin-bottom: 15px;
}

.footerStatus a:first-child {
    font-weight: 500;
}

.footerStatus a {
    color: inherit;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    text-decoration: none;
}
.footerMeta .contactLink {
    color: var(--brand);
    text-decoration: none;
}

@media screen and (max-width: 1100px) {
    .footerContent {
        flex-direction: column-reverse;
    }
}

.footerContent {
    display: flex;
    flex-grow: 1;
    padding: 30px 0;
}

@media screen and (max-width: 1100px) {
    .footerMeta>* {
        margin-right: 20px;
    }
}

.footerIcon {
    height: 26px;
    padding-top: 10px;
}

@media screen and (max-width: 850px) {
    .footerList {
        flex-direction: column;
    }
}

@media screen and (max-width: 1100px) {
    .footerList {
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 30px;
        width: 100%;
    }
}

.footerList {
    display: flex;
    justify-content: space-evenly;
    width: 80%;
}

@media screen and (max-width: 1100px) {
    .footerList>ul:first-of-type {
        padding: 0;
    }
}

@media screen and (max-width: 850px) {
    .footerList>ul {
        padding-left: 0;
        text-align: center;
    }
}

.footerList>ul {
    list-style: none;
    margin-top: 0;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;
}

.footerList>ul>li:first-child {
    font-weight: 500;
    margin-bottom: 5px;
}

.footerList>ul>li {
    padding-top: 1px;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
}

.footerList>ul>li>a {
    color: #4d4d4d;
    display: block;
    font-weight: 200;
    height: 30px;
    line-height: 30px;
    margin-bottom: 5px;
    text-decoration: none;
}
.home_container__3PaTk {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px -20px;
}

@media screen and (max-width: 800px) {
    .home_item__dpHbs {
        width: calc(100% - 40px);
    }
}

@media screen and (max-width: 1100px) {
    .home_item__dpHbs {
        width: calc(50% - 40px);
    }
}

.home_item__dpHbs {
    border: 1px solid #eaeaea;
    border-radius: 5px;
    box-sizing: border-box;
    margin: 20px;
    overflow: hidden;
    transition: box-shadow .2s ease-in-out;
    /* width: calc(33.3333% - 40px); */
    min-width: 300px;
}

.home_item__dpHbs a {
    text-decoration: none;
}

.home_previewContainer__4mYy3 {
    height: 300px;
    overflow: hidden;
}

.home_preview__v6ule {
    height: 200%;
    pointer-events: none;
    transform: scale(.5);
    transform-origin: 0 0;
    width: 200%;
}


.home_info__eUNH8 {
    box-shadow: 0 0 10px #0000000d, 0 -1px #00000008;
    display: flex;
    flex-direction: column;
    position: relative;
}

.home_metaWr__JjU6G {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.home_accessInfoWrapper__6-en6 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 10px 0 10px 20px;
    width: 238px;
}

.home_responsiveWrapper__CK07p {
    max-width: 80%;
}

.home_info__eUNH8 a {
    color: #24292e;
    display: flex;
    font-weight: 500;
    text-decoration: none;
}

.home_info__eUNH8 .home_link__ycPy1 {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.statusBadge_badgeWr__Q2jEs>a {
    display: inline-flex;
    text-decoration: none;
}

.home_info__eUNH8 a {
    color: #24292e;
    display: flex;
    font-weight: 500;
    text-decoration: none;
}
.statusBadge_free__P\+4-r {
    background: #e9ecef;
    color: #495057;
}

.statusBadge_badge__vJJ9X {
    border-radius: 10px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    height: 20px;
    line-height: 20px;
    padding: 0 9px;
    transition: all .1s ease-in-out;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
}

.button {
    align-items: center;
    background: #fff;
    border: none;
    border-radius: 6px;
    box-shadow: inset 0 0 0 1px #00000017, #1f23280a 0 1px 0 0;
    box-sizing: border-box;
    color: #383838;
    cursor: pointer;
    display: inline-flex;
    font-family: inherit;
    font-size: 13px;
    font-weight: 500;
    height: 34px;
    justify-content: center;
    line-height: 30px;
    outline: none;
    padding: 0 13px;
    text-decoration: none !important;
    transition: all .1s ease-in-out;
    transition-property: background, color;
    white-space: nowrap;
}

.button svg {
    width: 20px;
}

.home_info__eUNH8 .home_secondary__g-8dp {
    color: #4d4d4d;
    display: block;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.home_actions__8YdPl {
    margin-bottom: 50px;
}

.button--primary{
    background: #000;
    box-shadow: inset 0 0 0 1px #00000017, #1f23281a 0 1px 0 0;
    color: #fff;
}

.button svg {
    width: 20px;
}