._58ce83d8 {
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 99;
  
  min-height: 70px;
  align-items: center;
 
}
._5859e61e {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
._157dc3d3 {
  height: 70px;
  display: flex;
  padding: 0 30px;
  position: relative;
  flex-grow: 1;
  align-items: center;
  border-radius: 5px;
  flex-direction: row;
  --nav-flyout-bg: #fff;
}

.um-nav-wrapper {
  color: #212529;
  display: flex;
  flex-grow: 1;
  align-items: center;
}
.um-nav-wrapper.um-classic .um-nav-children {
  margin-right: auto;
}
._32caed59 {
  margin: 0;
  margin-right: auto;
}
._32caed59 > a,
._32caed59 > span {
  color: inherit;
  display: flex;
  align-items: center;
  line-height: 0;
}
._32caed59 > a > div + span,
._32caed59 > span > div + span {
  margin-left: calc(15px * 1);
}
.um-nav-links {
  margin: 0;
  display: flex;
  padding: 0;
  font-size: 15px;
  list-style: none;
  align-items: center;
}
.um-nav-links > li {
  position: relative;
}
._b9923f60 {
  display: block;
  height: 24px;
  width: 24px;
}
.um-nav-buttons {
  display: flex;
  align-items: center;
  margin-left: 20;
}

.btn {
  color: #212529;
  border: none;
  cursor: pointer;
  margin: 0 5px;
  display: inline-flex;
  outline: none;
  padding: 4px 10px;

  position: relative;
  background: #eee;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  align-items: center;
  border-radius: 3px;
  justify-content: center;
  text-decoration: none;
}
.um-nav-drawerTrigger {
  width: 3em;
  border: none;
  height: 3em;
  margin: 0;
  padding: 0;
  position: relative;
  background: transparent;
  border-radius: 4px;
}
.um-nav-drawerTrigger::after {
  top: 9px;
  left: 7px;
  width: 26px;
  height: 4px;
  content: "";
  opacity: 0.8;
  position: absolute;
  background: #343a40;
  box-shadow: 0 8px 0 #343a40, 0 16px 0 #343a40;
  border-radius: 2px;
}

@keyframes keyframes-navbar {
  0% {
    opacity: 0;
    transform: scale(0.9) translate(0, -10px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate(0, 0);
  }
}
.um-nav-drawer {
  position: absolute;
  top: 20%;
  right: 20px;
  background-color: #fff;
}

.um-nav-drawer header {
  display: flex;
  padding: 5px;
  border-bottom: 1px solid #f1f3f5;
  justify-content: flex-end;
}
.um-nav-drawer header .um-nav-drawerClose {
  all: unset;
  width: 40px;
  border: none;
  cursor: pointer;
  height: 40px;
  margin: 0;
  padding: 0;
  font-size: 30px;
  background: transparent;
  text-align: center;
}
.um-nav-drawer .um-drawer-links {
  display: flex;
  list-style: none;
  flex-direction: column;
}
.um-nav-drawer .um-drawer-links > li {
  border-bottom: 1px solid #f1f3f5;
}
.um-nav-drawer .um-drawer-links > li > a {
  font: inherit;
  display: block;
  padding: 12px 20px;
  font-size: 1em;
}
.um-nav-drawer .um-drawer-buttons {
  margin: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.um-nav-drawer .um-drawer-buttons button {
  width: 100%;
  flex-grow: 1;
  font-size: 15px !important;
  margin-bottom: 10px;
}
.btn span {
  text-decoration: none;
}
@media (max-width: 992px) {
  .um-nav-links,
  .um-nav-buttons {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .drawerTrigger  {
    display: none !important;
  }
}

.wr {
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  position: relative;
  max-width: 550px;
  box-sizing: border-box;
}
._18a9f441 {
  display: flex;
  justify-content: center;
}
._f6f5267b {
  border: 1px solid #f1f3f5;
  display: flex;
  padding:6px 20px;
  z-index: 10;
  position: relative;
  flex-grow: 1;
  margin-top: 20px;
  max-width: 700px;
  background: #fff;
  box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.2);
  align-items: center;
  border-radius: 6px;
  flex-direction: row;
  --nav-flyout-bg: #fff;
}

._f4c879d8 {
    display: flex;
    z-index: 10;
    position: relative;
    flex-grow: 1;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 40px -10px;
    align-items: center;
    flex-direction: row;
    --nav-flyout-bg: #fff;
    border: 1px solid #f1f3f5;
    padding: 15px 20px;
    background: #fff;
    border-radius: 5px;
    margin-top: 15px;
}._9698a7cf {
    margin: 0px auto 0px 0px;
}
._9698a7cf > a, ._9698a7cf > span {
   
    color: inherit;
    display: flex;
    align-items: center;
    line-height: 0;
}