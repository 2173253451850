.wrapper {
  width: 100%;
  overflow-x: hidden;
 position: relative;

}


.header {
  text-align: center;
  
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  
}

.logo {
  width: 30%;
  height: 30%;
  margin-bottom: 20px;
}

.button {
  background-color: #f44336;
 
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  width: 150px;
  overflow: hidden;
 
}

.button:hover {
  background-color: #d32f2f;
}


.exchanges {
  text-align: center;
  background-color: #121212;
  padding: 50px 0;
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.exchangesGrid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 65%;
  height: 100%;
  align-items: start;
}

.exchangeIcon {
  width: 250px;
  height: 100px;
  margin: 20px;
}


.howToBuy {
  text-align: center;
 
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.buySteps {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
   width: 100%;
   height: 100%;
}

.buyStep {
  width: 23%;
  position: relative;
  background-color: #1d1d1d;
  height: 254px;
  text-align: left;
  font-size: 30px;
  color: #fff;
  font-family: 'Luckiest Guy';
  display: flex ;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  grid:20px;
  }



.helmet {
  text-align: center;
  
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.helmetImage {
  width: 200px;
  margin-bottom: 20px;
}


.tokenomics {
  text-align: center;
  
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
  


.socials {
  text-align: center;
  
 display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.socialIcons {
  display: flex;
  justify-content: center;
}

.socialIcon {
  width: 50px;
  margin: 10px;
}


@media (max-width: 768px) {
  .header, .exchanges, .howToBuy, .helmet, .tokenomics, .socials {
    padding: 30px 0;
  }

  .buySteps {
    flex-direction: column;
  }

  .buyStep {
    width: 100%;
  }

  .exchangesGrid {
    flex-direction: column;
  }

  .socialIcons {
    flex-direction: column;
  }
}


.poweredBySolana {
  width: 50%;
  position: relative;
  font-size: 20px;
  line-height: 28px;
  
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
 
  overflow: hidden;
}

 

 #google_translate_element {
width: 300px;
float: right;
text-align: right;
display: block;
}
.goog-te-banner-frame.skiptranslate {
display: none !important;
}
body {
top: 0px !important;
}
#goog-gt-tt {
display: none !important;
top: 0px !important;
}
.goog-tooltip skiptranslate {
display: none !important;
top: 0px !important;
}
.activity-root {
display: hide !important;
}
.status-message {
display: hide !important;
}
.started-activity-container {
display: hide !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
display: none;
}

.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q {
display: none;
background-color: unset !important;
box-shadow: none !important;
}

.Icons{
position: relative;
width: 70%;
height: 100%; 
display: flex; 
justify-content: space-around;
align-items: center;
}

.Helemtdiv{
width: 100%;
height: 100%; 
position: relative;
display: flex; 
align-items: center;
justify-content: center;
}

.heroImage{
  width: 50%;
}
.heroText{
  width: 50%;
}
.helmetText{
  font-size: 90px;
}
.tokenomicsText{
  font-size: 90px;
}
.tokenomicsDiv1{
  width: 50%;
}
.tokenomicsDiv2{
  width: 50%;
}
.footertext{
  font-size: 90px;
}
@media screen and (max-width: 725px) {
.Icons {
position: relative;
width: 100%;
height: 100%;
display: flex;
justify-content: space-around;
align-items: center;
flex-direction: row;
}

.Helemtdiv {
width: 100%;
height: 100%;
position: relative;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
}
}


.SectionTwo{
display: flex; 
justify-content: center;
 align-items: center; 
}
.HemeltText{
font-size: 90px;
}
.TokenText{
font-size: 90px;
}
.TokenImageDIv{


display: flex;
align-items: center;
justify-content: center;
}

.howtobuy{
font-size: 86px;
}
.Socialtext{
font-size: 90px;
}

.SocailIcon{

display: flex; 
align-items: center;
justify-content: space-evenly;
}

.abouttext{
width: 90%;
}
.TokenText1{
font-size: 25px;
}

@media screen and (max-width: 725px) {
.SectionTwo{
display: flex; 
justify-content:center; 
align-items:center; 
flex-direction: column;
}
.HemeltText{
font-size: 30px;
}
.TokenText{
font-size: 30px;
}
.TokenText1{
font-size: 15px;
}
.TokenImageDIv{
flex-direction: column;
display: flex;
align-items: center;
justify-content: center;
}
.howtobuy{
font-size: 30px;
}
.Socialtext{
font-size: 30px;
}
.SocailIcon{

display: flex; 
align-items: center;
justify-content: space-evenly;
flex-direction: row;
}

.abouttext{
width: 90vw;
}
.hero{
  flex-direction: column;
}
.heroImage{
  width: 100%;
}
.heroText{
  width: 100%;
}
.helmetText{
  font-size: 30px;
}
.helmetDiv{
  flex-direction: column;
}
.tokenomicsText{
  font-size: 30px;
}
.tokenomicsDiv{
  flex-direction: column;
}
.tokenomicsDiv1{
  width: 100%;
}
.tokenomicsDiv2{
  width: 100%;
}
.footertext{
  font-size: 30px;
}
}

@media screen and (max-width: 400px) {
.TokenText1{
font-size: 15px;
}
}

