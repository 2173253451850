/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  section {
    width: 100%;
    height: 100vh;
    background-color: black;
  } */

/* ========= Navigation =========== */
.Navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
  padding: 10px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: start;
}

.logo a img {
  height: 35px;
  width: 35px;
}

nav .nav-item li {
  display: inline-block;
  list-style: none;
  margin: 5px;
}

nav .nav-item li a {
  display: inline-block;
  /* color: white; */
  font-size: 20px;
  text-decoration: none;
}

.navbar-nav .nav-item a:hover {
  text-decoration: underline;
}

.buttoncolor {
  margin: 5px;
  color: rgb(0, 0, 0);
  border: none;
  cursor: pointer;
  display: inline-flex;
  outline: none;
  /* padding: 0.7em 0.8em; */
  padding: 4px;
  position: relative;
  /* font-size: 13px; */
  background: rgb(236, 235, 235);
  box-shadow: 0px 2px 5px 0px rgba(221, 219, 219, 0.959);
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  align-items: center;
  border-color: rgb(220, 213, 213);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  justify-content: center;
  text-decoration: none;
  width: 90px;
  height: 35px;
  overflow: hidden;
}

nav .nav-item .nav-link button:hover {
  background-color: rgba(221, 222, 224, 0.84);
}

/* =====================Subheader============== */
.Subheader {
  background-color: #fafafa;
  height: 7px;
  background: var(--bg-header);
  box-shadow: 0 1px #eaeaea;
  left: 0;
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  top: 0;
  z-index: 100;
}

/* =========== Welcome to cofee hub=========== */
/* .buttoncolor{
    background-color:#f8f9fa;
  } */
.menu {
  border-radius: 20px;
  border: 1px solid #f1f3f5;
}

.heading {
  font-size: 56px;
  font-weight: 600;
  color: #212529;
}

.buttoncolor:hover {
  background-color: rgb(219, 214, 214);
}

.heading2 {
  font-size: 29px;
  font-weight: 600;
  color: #212529;
}

.card {
  background-color: #f8f9fa;
  /* background-image: linear-gradient(
    45deg,
    rgb(255, 255, 255) 0%,
    rgb(241, 242, 243) 100%
  ); */
  border: 1px solid rgb(235, 235, 235);
  border-radius: 10px;
  margin: 16px;
  padding: 16px;
  box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);
}

.footer-links p {
  margin: 0;
  padding: 0;
}

.footer-links p a {
  text-decoration: transparent;
  color: #212529;
}

.footer-links p a:hover {
  text-decoration: underline;
}

.sidebaritem {
  background-color: rgb(248, 247, 247);
  color: black;
  border: 1px solid rgb(233, 232, 232);
  border-radius: 7px;
  margin: 10px 20px;
  height: 40px;
  padding: 10px;
}

.sidebaritem:hover {
  background-color: #fc71eb;
}

.sidebaritem:hover img {
  visibility: visible;
}

.squareicon {
  color: rgb(175, 181, 209);
  height: 10px;
}
/* 
.landingpage {
  background: linear-gradient(120deg, #4103d2, #fbdef1);
  animation: gradientAnimation 10s ease infinite;
  text-align: center;
  font-weight: bolder;
} */

/* @keyframes gradientAnimation {
  100% {
    background-position: 100% 80%;
  }

  50% {
    background-position: 0% 10%;
  }
} */

.editing_wrapper__Wijfx {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #eaeaea, 0 0 5px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  /* margin: 15px;  */
  overflow-y: scroll;
  position: relative;
  /* max-height: 500px;  */
  scrollbar-width: none;

  padding-bottom: 200px;
}

.editing_wrapper__Wijfxnew {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #eaeaea, 0 0 5px rgba(0, 0, 0, 0.05);
  margin: 0px 10px;
  position: relative;
}

.nav_nav__LpVJF {
  background-color: #fff;
  border-top-right-radius: 10px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.03), 0 5px 10px -5px rgba(0, 0, 0, 0.07);
  box-sizing: border-box;

  z-index: 100;
}

.nav_pagePickerWr__vnrHs {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.nav_nav__LpVJF .nav_item__Yxa31.nav_page__g0\+lb {
  flex-grow: 1;
  flex-shrink: 1;
}

.nav_nav__LpVJF .nav_item__Yxa31:last-child {
  margin-right: 0;
}

.nav_nav__LpVJF .nav_item__Yxa31:first-child {
  margin-left: 0;
}

.nav_nav__LpVJF .nav_item__Yxa31 {
  all: unset;
  align-items: center;
  background-color: initial;
  border-radius: 6px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.09),
    rgba(31, 35, 40, 0.04) 0 1px 0 0;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  font-size: 13px;
  font-weight: 500;
  height: 34px;
  line-height: 34px;
  margin-left: 10px;
  margin-right: 10px;
  min-width: 0;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
}
.nav_item__Yxa31:hover {
  background-color: #dfe5f6;
  border-color: #dfe5f6;
  color: #193fa2;
}
.nav_nav__LpVJF_span {
  margin-left: 5px;
  max-width: 132px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: black;
}
.sectionPreview_focus__zwCCI {
  background-color: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 6px;
  box-shadow: 0 1px 0 0 rgba(31, 35, 40, 0.04);
  box-sizing: border-box;
  color: #4d4d4d;
  cursor: pointer;
  height: 24px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 0 0;
  width: 24px;
}
.sectionPreview_focus__zwCCI {
  visibility: hidden;
}
.fwf {
  color: red;
}
.item_item__rs7kB:hover .sectionPreview_focus__zwCCI {
  visibility: visible;
}

.sectionPreview_focus__zwCCI:hover {
  border-color: rgba(24, 106, 255, 0.6);
}

.meta_metaBox__TIPHD {
  border-bottom: 1px solid #eaeaea;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 5px 20px 20px 15px;
  position: relative;
}

.meta_metaBoxIcon__Ae6z2 {
  color: #8c8c8c;
  left: 20px;
  margin-left: 0 !important;
  position: absolute;
  top: 14px;
}

.meta_metaBox__TIPHD span:nth-child(2) {
  font-size: 14px;
  font-weight: 500;
}
.meta_metaBox__TIPHD:hover :nth-child(2) {
  color: #3350ff;
}

.meta_metaBox__TIPHD span:nth-child(3) {
  color: #4d4d4d;
  font-size: 12px;
}

.meta_metaBox__TIPHD > svg {
  color: #4d4d4d;
  position: absolute;
  right: 22px;
  top: 20px;
}
.meta_metaBox__TIPHD {
  position: relative;
}

.meta_metaBox__TIPHD:hover .arrow {
  transform: translateX(3px);
  transition: transform 0.2s ease-in-out;
}
.meta_metaBox__TIPHD span {
  margin-left: 34px;
}

.overview_standaloneItem__IoH4J {
  margin: 10px 20px;
}

.item_item__rs7kB {
  background-color: #f2f2f3;
  border-radius: 7px;
  box-sizing: border-box;
  color: #212529;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  min-height: 40px;
  position: relative;
  transition: box-shadow 0.2s ease-in-out;
  transition-property: border-color, border-style, box-shadow, background;
  -webkit-user-select: none;
  user-select: none;
}

.sectionPreview_sectionPreview__fStoX {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  padding: 10px 12px;
  position: relative;
  white-space: nowrap;
}

.item_item__rs7kB:hover {
  background-color: rgba(33, 190, 63, 0.15);
}

.sectionPreview_firstRow__weeLn {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.sectionPreview_headerIcon__ontsV {
  background-color: #f55e49;
  border-radius: 1px;
  height: 4px;
  margin-bottom: 12px;
  margin-right: 8px;
  position: relative;
  width: 16px;
}

.sectionPreview_headerIcon__ontsV::after {
  background-color: #24292e;
  border-radius: 1px;
  content: "";
  height: 8px;
  left: 0;
  opacity: 0.2;
  position: absolute;
  right: 0;
  top: 6px;
}

.sectionPreview_sectionMenu__hl3Cr.sectionPreview_small__Vsbac {
  height: 24px;
  width: 24px;
}

.sectionPreview_sectionMenu__hl3Cr {
  all: unset;
  align-items: center;
  background-color: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 6px;
  box-shadow: 0 1px 0 0 rgba(31, 35, 40, 0.04);
  box-sizing: border-box;
  color: #24292e;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  font-size: 13px;
  font-weight: 500;
  justify-content: center;
  line-height: 34px;
  margin-right: 0;
  min-width: 0;
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  user-select: none;
  white-space: nowrap;
}

.sectionPreview_defaultIcon__HR7Kh {
  background-color: #c3a3ff;
  border-radius: 2px;
  flex-shrink: 0;
  height: 8px;
  margin-left: 6px;
  margin-right: 8px;
  position: relative;
  width: 8px;
}

.item_addPlus__0h\+F5::before {
  border-radius: 3px;
  content: "";
  height: 2px;
  left: 6px;
  position: absolute;
  top: 9px;
  width: 8px;
}

.item_addPlus__0h\+F5::after {
  transform: rotate(90deg);
}

.item_add__4nGfJ {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  left: 0;
  opacity: 1;
  position: absolute;
  top: -15px;
  transition: opacity 0.2s ease-in-out;

  z-index: 10;
}

.item_add__4nGfJ:hover .item_addPlus__0h\+F5::after,
.item_add__4nGfJ:hover {
  background-color: #186aff;
}

.item_addPlus__0h\+F5::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.item_addPlus__0h\+F5::after {
  border-radius: 3px;
  content: "";
  height: 2px;
  left: 6px;
  position: absolute;
  top: 9px;
  width: 8px;
}

.ladingpagehedding {
  color: white;
  font-size: 60px;
}

.landingpageAi {
  background-image: linear-gradient(to right, #fc71eb, #e4c3fd);
  -webkit-background-clip: text;
  color: transparent;
}

.landingpageinput {
  width: 400px;
  height: 60px;

  border-style: none;
  border-collapse: collapse;
  outline-style: none;
}

.landingpagediv {
  width: 500px;
  background-color: white;
  border-radius: 10px;
  padding: 4px;
}

.landingpagebutton {
  width: 100px;
  height: 40px;
  margin: 10px;
  text-align: center;
  border-style: none;
  border-radius: 10px;
  background-color: #6423fc;
  color: white;
  font-size: 20px;
}

.landingpagenav a {
  color: white;
  position: relative;
  margin: 20px;
  top: 25px;
  left: 330px;
  text-decoration: none;
  font-size: 18px;
}

.landingpageloga {
  position: relative;
  left: 10px;
  top: 10px;
}

.scrolling-text {
  white-space: nowrap;
  animation: scroll-left 20s linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}


.container {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  /* min-width: 100%; */
  padding: 1px;
}

.Navbarcontainer {
  margin: 0 auto;
  /* overflow: hidden;  */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}

.Backbutton {
  border-style: none !important;
  display: flexbox;
  border-radius: 4px;
  justify-items: center;
}

.Downloadsourcecode {
  position: relative;
  right: 20px;
}

.mode_mode__w2z3S button {
  background: transparent;
  border: none;
  color: var(--color-lighter);
  cursor: pointer;
  font-family: inherit;
  font-size: 13px;
  font-weight: 500;
  /* margin-right: 20px; */
  outline: none;
  padding: 5px 0 15px;
}

.form_form__Sku4X {
  --field-height: 34px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100%;
  padding: 0;
}

.form_label__27psn {
  align-items: center;
  color: #4d4d4d;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  justify-content: space-between;
  margin-left: 5px;
}

.form_group__kDXNb {
  display: flex;
  flex-grow: 1;
  margin-bottom: 30px;
  min-width: 0;
  position: relative;
}

.form_row__2\+BJ3 {
  display: flex;
}

.editinput {
  border-radius: 4px;
  display: block;
  font-size: 12px;
  margin-bottom: -8px;
  margin-left: 6px;
  margin-top: 5px;
  padding: 0 4px;
  position: relative;
  z-index: 3;
}

.nav_nav__LpVJF {
  background-color: #fff;
  border-top-right-radius: 10px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.03), 0 5px 10px -5px rgba(0, 0, 0, 0.07);
  box-sizing: border-box;

  width: 400px;
  z-index: 100;
}
.nav_main__HWvnb {
  display: flex;
  padding: 15px 20px;
  z-index: -1;
  position: relative;
}
.nav_back__\+Ex8F {
  align-items: center;
  background-color: initial;
  border: none;
  border-radius: 6px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.09),
    rgba(31, 35, 40, 0.04) 0 1px 0 0;
  color: #24292e;
  cursor: pointer;
  display: flex;
  height: 34px;
  justify-content: center;
  width: 40px;
}
.nav_nav__LpVJF svg {
  flex-shrink: 0;
}
button {
  all: unset;
}
.nav_nav__LpVJF .nav_item__Yxa31.nav_section__046fK {
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 0;
  padding-left: 8px;
}
.nav_sectionMenu__FXNmQ {
  all: unset;
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.09),
    rgba(31, 35, 40, 0.04) 0 1px 0 0;
  box-sizing: border-box;
  color: #4d4d4d;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  font-weight: 500;
  height: 34px;
  justify-content: center;
  line-height: 34px;
  margin-right: 0;
  min-width: 0;
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: 34px;
}
.mode_mode__w2z3S {
  padding: 0 20px;
}

.mode_mode__w2z3S button.mode_active__2-8Yb {
  box-shadow: inset 0 -2px 0 #3350ff;
  color: #24292e;
}
.mode_mode__w2z3S button {
  background: transparent;
  border: none;
  color: var(--color-lighter);
  cursor: pointer;
  font-family: inherit;
  font-size: 13px;
  font-weight: 500;
  margin-right: 20px;
  outline: none;
  padding: 5px 0 15px;
}
.editing_content__RXzxk {
  padding: 10px 20px 50px;
}

.expandable_expandable__3BGe7.expandable_nested__UjqMd {
  margin: 0;
}

.expandable_expandable__3BGe7 {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.expandable_title__Pcsbd {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: space-between;
  padding: 12px 15px;
  position: relative;
}
.notice {
  position: relative;
  display: inline-block;
  text-align: left;
  opacity: 1;
  direction: ltr;
  border-radius: 8px;
  transition: opacity 0.25s ease 0s;
  touch-action: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
}
.notice2 {
  height: 12px;
  width: 12px;
  background: rgb(255, 255, 255);
  display: inline-block;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  transform: translateX(2px);
  top: 2px;
  outline: 0px;
  border: 0px;
  transition: background-color 0.25s ease 0s, transform 0.25s ease 0s,
    box-shadow 0.15s ease 0s;
}

.inputstyle {
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  width: 1px;
}
.form_divider__fd\+a0 {
  border-bottom: 1px solid #eaeaea;
  height: 0;
  margin: 15px 0;
  width: 100%;
}
.expandable_expandable__3BGe7.expandable_padContent__JcqHj
  .expandable_content__7Ht4T {
  padding: 0 10px;
}
.form_field__d31bq {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 3px;
  position: relative;
}
.form_field__d31bq.form_inline__6vby7 {
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 5px;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: flex-end;
  min-height: 34px;
  padding: 5px;
}
.form_field__d31bq.form_inline__6vby7 .form_label__27psn {
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: auto;
}

.form_field__d31bq.form_inline__6vby7 > * {
  margin-left: 10px;
}

label {
  cursor: default;
}

.colors_swatch__gosbA {
  cursor: pointer;
  height: 26px;
  position: relative;
  width: 26px;
}
.colors_swatchColor__WaTUr {
  border-radius: 3px;
  bottom: 0;
  box-shadow: inset 0 0 0 1px #00000014;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.form_field__d31bq .form_tiptap__s0JN1 {
  padding: 7px 10px;
}

.form_field__d31bq .form_value__oIwpW,
.form_field__d31bq > input,
.form_field__d31bq > textarea {
  background-color: initial;
  background: #f4f4f4;
  border: none;
  border-radius: 6px;
  box-sizing: border-box;
  color: #24292e;
  font-family: inherit;
  font-size: 13px;
  min-height: 34px;
  outline: none;
  padding: 0 10px;
  position: relative;
}

.ProseMirror {
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: break-spaces;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0;
}
.form_more__6E9XQ {
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  position: absolute;
  right: 3px;
  top: 3px;
  width: 24px;
  z-index: 0;
}
.form_group__kDXNb .form_field__d31bq {
  margin-bottom: 3px;
  padding: 0;
}

.form_field__d31bq.form_size--1x1__H0EdC,
.form_field__d31bq.form_size--2x2__J8UQE {
  align-items: center;
  background: #f4f4f4;
  border-radius: 6px;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
}
.form_field__d31bq.form_size--2x2__J8UQE {
  height: 74px;
  width: 74px;
}
.form_row__2\+BJ3 .form_field__d31bq {
  flex-grow: 0;
  min-width: 0;
}
.picker_preview__QSKw4 {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 34px;
  justify-content: space-between;
  padding: 0 10px;
}

.form_field__d31bq > textarea {
  padding: 8px 10px;
  resize: none;
}

.form_field__d31bq .form_value__oIwpW,
.form_field__d31bq > input,
.form_field__d31bq > textarea {
  background-color: initial;
  background: #f4f4f4;
  border: none;
  border-radius: 6px;
  box-sizing: border-box;
  color: #fff;
  font-family: inherit;
  font-size: 13px;
  min-height: 32px;
  outline: none;
  padding: 0 10px;
  position: relative;
}
.form_row__2\+BJ3 {
  display: flex;
}
.ProseMirror {
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: break-spaces;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0;
}
.form_field__d31bq .form_tiptap__s0JN1 {
  padding: 7px 10px;
}

.form_field__d31bq .form_value__oIwpW,
.form_field__d31bq > input,
.form_field__d31bq > textarea {
  background-color: initial;
  background: #f4f4f4;
  border: none;
  border-radius: 6px;
  box-sizing: border-box;
  color: #24292e;
  font-family: inherit;
  font-size: 13px;
  min-height: 32px;
  vertical-align: baseline;
  outline: none;
  padding: 0 10px;
  position: relative;
}

.ProseMirror {
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: break-spaces;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0;
}

.contentthemebutton {
  background-color: black;
  color: white;
}

.form_sortable__FLtVI {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.form_listItem__puOqW {
  display: flex;
  position: relative;
}
[data-rbd-drag-handle-context-id="4"] {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  touch-action: manipulation;
}

.form_headerHandle__33dpI {
  height: 34px;
  left: -25px;
  position: absolute;
  top: 2px;
  width: 25px;
}
.form_headerHandle__33dpI::before {
  background: #8c8c8c;
  border-radius: 3px;
  box-shadow: 0 0 0 0, 5px 0 0 0, 0 5px 0 0, 5px 5px 0 0, 0 10px 0 0,
    5px 10px 0 0;
  color: #8c8c8c;
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 3px;
}
.form_listItem__puOqW:hover > .form_headerRemove__mJ7no {
  display: flex;
}

.form_headerRemove__mJ7no {
  align-items: center;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
  color: #4d4d4d;
  cursor: pointer;
  display: none;
  font-size: 12px;
  font-weight: 500;
  height: 16px;
  justify-content: center;
  position: absolute;
  right: -10px;
  top: -7px;
  width: 16px;
  z-index: 1;
}
.form_row__2\+BJ3.form_hasMore__JC1Yq {
  padding-right: 29px;
}

.form_row__2\+BJ3 {
  display: flex;
}

.editor_wrapper__JGnig {
  --focus-bg: rgba(33, 190, 63, 0.15);
  --focus-border: rgba(41, 203, 69, 0.3);
  box-sizing: border-box;
  display: flex;
  height: 100vh;
  padding-top: 49px;
  position: relative;
  width: 100vw;
}

.form_field__d31bq > input[type="url"] {
  font-style: italic;
}

.form_field__d31bq > input {
  height: 32px;
}

input[type="url" i] {
  padding-block: 1px;
  padding-inline: 15px;
}
.form_emptyList__KNV3N {
  align-items: center;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  color: #4d4d4d;
  display: flex;
  justify-content: space-between;
  margin: 3px;
  padding: 15px;
}

.button--small {
  font-size: 13px;
  height: 28px;
  line-height: 28px;
  padding: 0 8px;
}

.button1 {
  align-items: center;
  background: #fff;
  border: none;
  border-radius: 6px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.09),
    rgba(31, 35, 40, 0.04) 0 1px 0 0;
  box-sizing: border-box;
  color: #383838;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 13px;
  font-weight: 500;
  height: 34px;
  justify-content: center;
  line-height: 30px;
  outline: none;
  padding: 0 13px;
  text-decoration: none !important;
  transition: all 0.1s ease-in-out;
  transition-property: background, color;
  white-space: nowrap;
}
.form_addBtn__NBb\+0 {
  align-self: flex-end;
}

.overlay_overlay__8dxAW {
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1), 0 4px 10px rgba(0, 0, 0, 0.07);
  cursor: default;
  display: flex;
  flex-direction: column;
  left: 0;
  max-height: calc(100vh - 20px) !important;
  max-width: calc(100vw - 20px) !important;
  position: fixed;
  top: 0;
  z-index: 1000;
}
.modal {
  display: none;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color:#174744;
  /* margin: 20% auto; */
  /* padding: 20px; */
  /* border: 1px solid #888; */
  width: 100%;
  /* max-width: 400px; */
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.media_preview__okAyH {
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: space-between;
  padding: 0px 5px 0px 10px;
}
.media_value__\+4kkg {
  display: flex;
}
.media_image__kN9DM {
  background: #e6e6e6;
  border-radius: 3px;
  box-sizing: border-box;
  height: 28px;
  max-height: 28px;
  max-width: 38px;
  object-fit: contain;
  padding: 0px 3px;
}
.form_group__kDXNb .form_label__27psn:not(.form_checkbox__aM2rA) {
  align-self: flex-start;
  background-color: #f4f4f4;
  border-radius: 4px;
  display: block;
  font-size: 12px;
  margin-bottom: -8px;
  margin-left: 6px;
  margin-top: 5px;
  padding: 0px 4px;
  position: relative;
  z-index: 3;
}
.auto-resize {
  position: relative;
  min-height: 34px;
  padding: 5px 10px;
  resize: none;
}
/* .form_field__d31bq > textarea {
  padding: 8px 10px;
  
} */

.form_field__d31bq > .form_value__oIwpW {
  align-items: center;
  display: flex;
  height: var(--field-height);
  padding: 0px 5px;
}

.form_field__d31bq .form_value__oIwpW,
.form_field__d31bq > input,
.form_field__d31bq > textarea {
  background: #f4f4f4;
  border: none;
  border-radius: 6px;
  box-sizing: border-box;
  color: #4d4d4d;
  font-family: inherit;
  font-size: 13px;
  min-height: 32px;
  outline: none;
  padding: 0px 10px;
  position: relative;
}

.css-1mu46ou-container {
  position: relative;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  flex-grow: 1;
  z-index: 1;
  min-height: 38px;
  line-height: initial;
  width: initial;
  opacity: 1;
}
.css-1ttbk4t-control {
  -webkit-box-align: center;
  align-items: center;
  background-color: transparent;
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 36px;
  position: relative;
  transition: all 100ms ease 0s;
  box-sizing: border-box;
  min-width: 100px;
  -webkit-box-flex: 1;
  flex-grow: 1;
  border: none;
  color: #24292e;
  width: initial;
  outline: 0px !important;
}
.css-10ec4sc {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex: 1 1 0%;
  flex-wrap: wrap;
  padding: 8px 8px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  -webkit-box-pack: start;
  justify-content: flex-start;
}
.css-1wa3eu0-placeholder {
  color: rgb(231, 227, 227);
  margin-left: 2px;
  margin-right: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
}
.css-1wy0on6 {
  -webkit-box-align: center;
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
}
.css-1hyfx7x {
  display: none;
}
.css-shfdzl-indicatorContainer {
  color: #4d4d4d;
  display: flex;
  padding: 7px;
  transition: color 150ms ease 0s;
  box-sizing: border-box;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  margin-top: 5px;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  z-index: 100;
}

.dropdown-options div {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-options div:hover {
  background-color: #f4f4f4 !important;
}

.form_field__d31bq > textarea {
  padding: 8px 10px;
  resize: none;
  overflow-y: hidden;
}

.form_field__d31bq .form_tiptap__s0JN1 {
  padding: 7px 10px;
}

.form_field__d31bq.form_size--1x1__H0EdC {
  height: 34px;
  width: 34px;
}

.form_field__d31bq.form_size--1x1__H0EdC {
  align-items: center;
  background: #f4f4f4;
  border-radius: 6px;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
}

/* desing  */

.editing_design {
  padding: 15px 0 50px;
}
.design_container {
  display: flex;
  margin: 0;
}
.design_options {
  border-bottom: 1px solid #eaeaea;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 10px;
}

.design_options > button {
  all: unset;
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  height: 40px;
  padding: 0 20px;
  position: relative;
}
.design_options > button:hover::before {
  background: #f5f5f5;
  border-radius: 5px;
  content: "";
  inset: 2px 10px;
  position: absolute;
  z-index: -1;
}
.design_options > button svg {
  margin-right: 20px;
}
.list_top {
  display: flex;
  flex-direction: column;
}
.list_header {
  all: unset;
  align-items: center;
  display: flex;
  flex-grow: 1;
  font-size: 13px;
  font-weight: 500;
  height: 40px;
  padding: 0 12px 0 20px;
  position: relative;
}
.list_header:not(.list_disabled__5JSNJ):hover::before {
  background: #f5f5f5;
  border-radius: 5px;
  content: "";
  inset: 2px 10px;
  position: absolute;
  z-index: -1;
}
.list_header > svg {
  margin-right: 20px;
}
.list_header .list_itemAdd {
  margin-left: auto;
}

.list_itemAdd {
  all: unset;
  align-items: center;
  border-radius: 4px;
  color: #8c8c8c;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  transition: background 0.2s ease;
  width: 30px;
}
.design_header {
  align-items: center;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  justify-content: flex-start;
  margin: 20px 20px 4px;
}

.list_draggable {
  position: relative;
}
.list_dragHandle-button {
  all: unset;
  cursor: grab;
  display: block;
  height: 34px;
  left: 50px;
  position: absolute;
  top: 2px;
  width: 25px;
}
.list_dragHandle-button::before {
  background: #8c8c8c;
  border-radius: 3px;
  box-shadow: 0 0 0 0, 5px 0 0 0, 0 5px 0 0, 5px 5px 0 0, 0 10px 0 0,
    5px 10px 0 0;
  color: #8c8c8c;
  content: "";
  display: block;
  height: 3px;
  opacity: 0.8;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 3px;
}
.list_item {
  align-items: center;
  display: flex;
  height: 36px;
  justify-content: space-between;
  padding-left: 80px;
  padding-right: 10px;
}
.list_trigger__q3zjm {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  font-size: 13px;
  height: 30px;
  padding: 0 10px;
  text-transform: capitalize;
}
.list_itemDelete {
  margin-right: 2px;
}

.list_itemAdd__lpQwP,
.list_itemDelete {
  all: unset;
  align-items: center;
  border-radius: 4px;
  color: #8c8c8c;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  transition: background 0.2s ease;
  width: 30px;
}

.popup {
  position: fixed;
  left: 40%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 4px 30px #0000001a, 0 4px 10px #00000012;
  cursor: default;
  display: flex;
  flex-direction: column;
  width: 300px;
}

.popup-content {
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  padding: 10px;
}

.overlayHeader {
  align-items: center;
  color: #24292e;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: space-between;
  line-height: 32px;
  padding-left: 15px;
  padding-right: 5px;
  padding-top: 4px;
}
.font_colors {
  border-bottom: 1px solid #eaeaea;
  display: flex;
  margin-bottom: 10px;
  padding: 0 15px 15px;
}
.font_color__sub {
  align-items: center;
  display: flex;
  margin-right: 20px;
}
.font_color__sub label {
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 5px;
}
.swatch_swatch__7xFNG {
  cursor: pointer;
  height: 26px;
  position: relative;
  width: 26px;
}
.swatch_swatchColor__ynsJn {
  border-radius: 3px;
  bottom: 0;
  box-shadow: inset 0 0 0 1px #00000014;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.listPicker_search__tqIWr {
  background: #f5f5f5;
  border: none;
  border-radius: 3px;
  line-height: 28px;
  margin: 0 15px 5px;
  outline: none;
  padding: 0 10px;
}
.listPicker_list__k2aIP {
  box-sizing: border-box;
  display: block;
  font-size: 13px;
  padding-bottom: 15px;
}

.listPicker_row__ISL1- {
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  line-height: 16px;
  margin: 0 15px;
  padding: 0 12px;
  width: calc(100% - 30px) !important;
}
.listPicker_row__ISL1- > span {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.fonts_typeSelect {
  margin: 0 15px 10px;
}
.styles_select {
  background-color: #f4f4f4;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 3px;
  position: relative;
}
.styles_marker {
  background-color: #fff;
  border-radius: 3px;
  bottom: 3px;
  box-shadow: 0 1px 4px #0000001a;
  position: absolute;
  top: 3px;
  transition: left 0.15s ease-in-out;
  z-index: 0;
}
.styles_option__f\+yN9 {
  cursor: pointer;
  flex-basis: 0;
  flex-grow: 1;
  opacity: 0.7;
  position: relative;
  text-align: center;
  transition: opacity 0.15s ease-in-out;
}
.styles_option__f\+yN9.styles_active__8RnsA {
  opacity: 1;
}

.styles_option__f\+yN9 {
  cursor: pointer;
  flex-basis: 0;
  flex-grow: 1;
  opacity: 0.7;
  position: relative;
  text-align: center;
  transition: opacity 0.15s ease-in-out;
}
.font_settings {
  border-top: 1px solid #eaeaea;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  margin: 5px 0 0;
  padding: 12px 15px 15px;
}
.font_settings li {
  display: flex;
  flex-direction: column;
}
.font_settings li label {
  color: #4d4d4d;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 2px;
}

.font_settings li input,
.font_settings li select {
  background: #0000;
  border: 1px solid #eaeaea;
  border-radius: 3px;
  box-sizing: border-box;
  color: inherit;
  display: block;
  height: 26px;
  padding: 0 5px;
  width: 60px;
}

.font_settings li select {
  width: 80px;
}

.spacing_container__DHmIC {
  display: flex;
  flex-direction: column;
  padding: 0 15px 15px;
  width: 240px;
}
.spacing_item__EstaE {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}
.overlay_overlay__8dxAW::after {
  background: #fff;
  bottom: 0;
  content: "";
  height: 15px;
  left: 13px;
  position: absolute;
  width: 30px;
  z-index: -1;
}
.spacing_item__EstaE label {
  color: #4d4d4d;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: -5px;
  text-transform: capitalize;
}
.slider_container__29ImU {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}
.slider_SliderRoot__vjUOH {
  align-items: center;
  display: flex;
  height: 30px;
  position: relative;
  touch-action: none;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
}
.slider_SliderRange__CooOM {
  background-color: #3350ff;
  border-radius: 2px;
  height: 100%;
  position: absolute;
}
.slider_SliderTrack__2UXOU {
  background-color: #eee;
  border-radius: 2px;
  flex-grow: 1;
  height: 4px;
  position: relative;
}
.slider_SliderThumb__pa1re {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px #0000004d;
  display: block;
  height: 16px;
  width: 16px;
}
.slider_container__29ImU .slider_customValue__PCqPu[type="number"] {
  -moz-appearance: textfield;
}

.slider_container__29ImU .slider_customValue__PCqPu {
  text-align: center;
  width: 60px;
}
.slider_container__29ImU input {
  background: #f4f4f4;
  border: #eee;
  border-radius: 3px;
  color: #24292e;
  margin-left: 10px;
  padding: 6px 8px;
  width: 36px;
}
:where(button, input, select) {
  margin: 0;
}
.overlay_clear__EdOFC {
  all: unset;
  animation: overlay_clearFade__gMIJQ 0.4s ease-out;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.4;
  margin-left: 5px;
  margin-right: auto;
  padding: 2px 7px;
}
@keyframes overlay_clearFade__gMIJQ {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Other */

.misc_container__F8COs {
  display: flex;
  flex-direction: column;
  margin: 0 15px 15px;
  width: 170px;
}
.misc_item__5-JsP {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.misc_item__5-JsP label {
  color: #4d4d4d;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
}
.misc_item__5-JsP input {
  background: #f4f4f4;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  color: #24292e;
  font-size: 14px;
  outline: none;
  padding: 5px 10px;
  width: 70px;
}
.misc_row__F7Lv- {
  display: flex;
  justify-content: space-between;
}

/* Shadow */
.shadow_container__AyT9K {
  display: flex;
  flex-direction: column;
  width: 320px;
}
.shadow_slider__GEWDd {
  border-bottom: 1px solid #eaeaea;
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
}
.detail_openBtn__x6SYz {
  align-items: center;
  background: #0000;
  border: none;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
}
.detail_openBtn__x6SYz svg {
  color: #8c8c8c;
  height: 18px;
  width: 18px;
}
.detail_openBtn__x6SYz:hover {
  color: #193fa2;
}
.shadow_values__xp2Js {
  padding: 0 15px 15px;
}
.shadow_shadows__59Hq5 {
  list-style: none;
  margin: 0;
  padding: 0;
}
.shadow_header__MMkCy {
  color: #4d4d4d;
  display: flex;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 5px;
}
.shadow_header__MMkCy span {
  display: flex;
  margin-right: 36px;
  width: 40px;
}
.shadow_shadow__1dKUp input[type="number"] {
  box-sizing: border-box;
  text-align: center;
  width: 50px;
}

.shadow_shadow__1dKUp input {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  background: #f5f5f5;
  border: none;
  border-radius: 3px;
  color: inherit;
  font-family: inherit;
  font-size: 12px;
  height: 26px;
  line-height: 26px;
  margin-right: 5px;
  outline: none;
  padding: 0 10px;
}
.shadow_shadow__1dKUp {
  display: flex;
  margin-bottom: 10px;
}
.shadow_shadow__1dKUp > div {
  flex-shrink: 0;
  margin-left: 5px;
}

.shadow_delete__-Poz0 {
  align-items: center;
  background: #0000;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  height: 26px;
  justify-content: center;
  margin-left: 28px;
  padding: 0;
  width: 26px;
}

/* Cards */
.picker_container__vy82H {
  display: flex;
  flex-direction: column;
  width: 280px;
}
.picker_presets__SmbLj {
  border-bottom: 1px solid #eaeaea;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0 8px 5px;
}
.picker_presets__SmbLj li {
  border: 1px solid #0000;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 2px;
  padding: 5px;
  position: relative;
}
.picker_presets__SmbLj li div {
  box-sizing: border-box;
  height: 30px;
  width: 36px;
}
.picker_settings__w8171 {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
}
.picker_setting__OsFeD {
  all: unset;
  align-items: center;
  display: flex;
  padding: 5px 15px;
}
.picker_setting__OsFeD label,
.picker_setting__OsFeD span {
  color: #4d4d4d;
  font-size: 12px;
  font-weight: 500;
  margin-right: auto;
}
.picker_setting__OsFeD .picker_background__YKaIH {
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #0000001a;
  display: block;
  height: 26px;
  width: 26px;
}
.picker_setting__OsFeD input {
  background: #f4f4f4;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  box-sizing: border-box;
  color: #24292e;
  font-size: 14px;
  outline: none;
  padding: 5px 10px;
  width: 60px;
}

/* Theme tab */

.colors_container {
  border: 1px solid #eaeaea;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  padding: 15px;
}

.form_field__d31bq.form_inline__6vby7.form_noBackground__-8BHM
  .form_label__27psn {
  margin-left: 0;
}
.colors_colorGroup__B8CxB {
  display: flex;
}
.colors_colorGroup__B8CxB > div {
  margin-right: 5px !important;
}

.theme_trigger__oO3ON {
  all: unset;
  align-items: center;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-left: 10px;
}
.theme_trigger__oO3ON span:last-child {
  align-items: center;
  background: #f5f5f5;
  border-radius: 6px;
  display: flex;
  height: 36px;
  justify-content: center;
  margin: 5px;
  width: 36px;
}

/* Buttons */
.buttons_container__vr6gx {
  border: 1px solid #eaeaea;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  padding: 15px;
}
.buttons_container__vr6gx h4 {
  font-size: 13px;
  font-weight: 500;
  margin: 0 0 5px;
}
.buttons_options__2xMXc {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.buttons_options__2xMXc li {
  align-items: center;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  margin-right: 15px;
  width: 100px;
}

.buttons_options__2xMXc li button {
  font-size: 14px !important;
  max-height: 100%;
  max-width: 100%;
  pointer-events: none;
}

.epb-base {
  color: rgb(0, 0, 0);
  background: rgb(240, 240, 240);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0);
  border-color: rgb(227, 227, 227);
  border-style: solid;
  border-width: 0;
  border-radius: 8px;
}

.ebp-btn {
  border: none;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: none;
  padding: 0.7em 0.8em;
  position: relative;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.buttons_options__2xMXc li.buttons_active__yogSh {
  background: #f5f5f5;
  border-color: #cdcdcd;
  box-shadow: 0 1px 0 0 #fff;
}

.buttons_options__2xMXc li {
  align-items: center;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  margin-right: 15px;
  width: 100px;
}
.epb-primary {
  color: rgb(255, 255, 255);
  background: rgb(61, 34, 186);
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.22);
  border-color: rgb(39, 39, 44);
}
.buttons_options__2xMXc li.buttons_presets__W-nCp {
  font-size: 12px;
  font-weight: 500;
  height: 30px;
}

.buttons_options__2xMXc li {
  align-items: center;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  margin-right: 15px;
  width: 100px;
}
.buttons_presets__W-nCp {
  margin-left: auto;
}
.buttons_switch__YLw75 {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  margin: 10px 0 20px;
}
.buttons_switch__YLw75 > div {
  margin: 0 5px;
}
.buttons_settings {
  display: flex;
}
.buttons_settings > button {
  all: unset;
  align-items: center;
  background: #f4f4f4;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-right: 10px;
  text-align: center;
  width: 40px;
}

.design_group__hqhLz {
  list-style: none;
  margin: 0;
  padding: 5px 20px;
}
.design_masonry__fQVWE {
  display: flex;
  margin-left: 0;
}
.design_masonry_column__jKgO\+:first-child {
  padding-left: 0;
}

.design_masonry_column__jKgO\+ {
  background-clip: padding-box;
  padding-left: 10px;
}

.design_structure__XsCcv.design_active__d6LAk {
  border-color: #3350ff;
}

.design_structure__XsCcv:hover {
  border-color: #8c8c8c;
}
.design_structure__XsCcv {
  background: #fafafa;
  border: 1px solid #eaeaea;
  border-radius: 7px;
  margin-bottom: 15px;
}
.design_structure__XsCcv .design_clickable__ijMgi {
  cursor: pointer;
}
.design_structure__XsCcv .design_thumbnail__gOD-e {
  padding: 5px;
  position: relative;
}
.design_structure__XsCcv .design_thumbnail__gOD-e img {
  border-radius: 5px;
  display: block;
  margin: auto;
  max-height: 300px;
  max-width: 100%;
}

.settings_settings__5lMi5 {
  background: #fff;
  border-radius: 0 0 8px 8px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.settings_settings__5lMi5 li {
  border-top: 1px solid #eaeaea;
  padding: 7px 10px;
}
.settings_settings__5lMi5 li label {
  align-items: center;
  color: #4d4d4d;
  display: flex;
  font-size: 11px;
  font-weight: 500;
  height: 26px;
  justify-content: space-between;
}

._f497df41 {
  right: 0;
  bottom: 0;
  position: relative;
  clip-path: polygon(10% 0, 0 100%, 100% 100%, 100% 0);
  object-fit: cover;
}

._e51b713c {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-sizing: border-box;
}

._f497df412 {
  display: flex;
  padding: 8px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);
  box-sizing: border-box;
  border-color: rgb(255, 255, 255);
  border-style: solid;
  border-width: 0px;
  border-radius: 10px;
}
._f497df413 {
  max-width: 50vw;
  box-sizing: border-box;
  flex-shrink: 0;
  padding-right: 50px;
  padding-top: 50px;
}
._f497df414 {
  max-width: 50vw;
  box-sizing: border-box;
  flex-shrink: 0;
}

/* Faq */
._9a49c734 {
  padding: 0 0 30px 0;
}
._d1646f8c {
  color: #212529;
  margin: 0;
  font-size: 1.05em;
  font-weight: 500;
}
._c53b3b37 {
  color: #212529;
  margin: 0 0 0 0;
  font-size: 40px;
}



/* cards */

._917a8cb2 {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
}

.separatedcards {
  color: #343a40;
  margin: 1em;
  display: flex;
  padding: 1em;
  flex-grow: 1;
  /* background: linear-gradient(45deg, rgb(255, 255, 255) 0%, rgb(241, 242, 243) 100%); */
  box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);
  box-sizing: border-box;
  flex-basis: 0;
  text-align: center;
  align-items: center;
  border-color: rgb(235, 235, 235);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
}
.countparent{
  color:  #212529;
  font-size: 1.8em;
  font-weight: 600;
}
.countspantext {
  font-size: 16px;
}


.sidecard {
  color: #343a40;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);
  box-sizing: border-box;
  background: linear-gradient(45deg, rgb(255, 255, 255) 0%, rgb(241, 242, 243) 100%);
  border: 1px solid rgb(235, 235, 235) ;
  border-radius: 10px;
}
.sidecard-child {
  display: flex;
  flex-grow: 1;
  flex-basis: 0px;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 1em;
  border-right: 1px solid rgb(235, 235, 235);
}



/* Features */
._c33e3ab2 {
  display: flex;
  flex-wrap: wrap;
}
._5a4edc19 {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  align-items: flex-start;
  flex-direction: column;
 
}
._e519f50a {
  font-size: 0.9em;
}
._2d4996c3 {
  color: #212529;
  margin: 0 0 0 0;
  font-size: 2.5em;
}
._1d2492ee {
  margin: 0.5em 0 30px 0;
  font-size: 1.2em;
}

._39a5731d {
  border: 1px solid #f1f3f5;
  display: flex;
  padding: 2em;
  box-sizing: border-box;
  margin-top: 2em;
  border-radius: 10px;
  flex-direction: column;
}
._7e96d1a2 {
  margin-bottom: 1em;
}
._9c827e5c {
  display: block;
  max-width: calc(150px* 1);
  max-height: 300px;
}
._063037f9 {
  font-size: 0.95em;
  margin-bottom: auto;
}
._72288a42 {
  display: flex;
  margin-top: 1em;
  align-items: center;
}
._6928058c {
  width: 50px;
  height: 50px;
  margin-right: 1em;
  border-radius: 50%;
}
._c8ae6a52 {
  display: flex;
  flex-direction: column;
}
._750519bb {
  font-size: 0.9em;
  font-weight: 500;
}
._2320f902 {
  font-size: 0.9em;
}
._c8e71cbb {
  width: calc(50% - 4em);
  margin-left: 3em;
}

.settings_settings__5lMi5 select {
  background:#f4f4f4;
  border: none;
  border-radius: 6px;
  text-align: center;
  box-sizing: border-box;
  color: #24292e;
  font-family: inherit;
  font-size: 13px;
  height: 26px;
  outline: none;
  padding: 0 10px;
  width: 80px;
}
option {
  font-weight: normal;
  display: block;
  min-height: 1.2em;
  padding: 0px 2px 1px;
  white-space: nowrap;
}

._c33e3ab3 {
  color: #343a40;
  display: flex;
  flex-wrap: wrap;  background: linear-gradient(45deg, rgb(255, 255, 255) 0%, rgb(241, 242, 243) 100%);;
  box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);
  box-sizing: border-box;
  border-color: rgb(235, 235, 235);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  padding: 20px;
  
};


.buttons_container__vr6gx{
  border: 1px solid var(--border);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  padding: 15px;
};
.h4{
  
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;

};





.buttons_container__vr6gx {
  border: 1px solid #eaeaea;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  padding: 15px;
}
.buttons_container__vr6gx h4 {
  font-size: 13px;
  font-weight: 500;
  margin: 0 0 5px;
}
.presets_presets__sNCDg {
  list-style: none;
  margin: 0;
  padding: 0;
}

.presets_presets__sNCDg li {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  margin: 0 5px;
  padding: 10px;
};
.presets_presets__sNCDg li button {
  font-family: inherit;
  font-size: 14px;
  margin-right: 10px;
}
.epb-104-base {
  color: rgb(0, 0, 0);
  border: none;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: none;
  padding: 0.7em 0.8em;
  position: relative;
  font-size: 14px;
  background: rgb(240, 240, 240);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0);
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  align-items: center;
  border-color: rgb(227, 227, 227);
  border-style: solid;
  border-width: 0;
  border-radius: 8px;
  justify-content: center;
  text-decoration: none;
}

.IconPiker {
  width: 50vw;
  height: 73vh;
  background: rgb(225, 17, 17);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  font-family: sans-serif;
};

.showdobutton{
  all: unset;
  align-items: center;
  background: #fefcfc;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-right: 10px;
  text-align: center;
  width: 40px;
}