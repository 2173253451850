@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

section {
  width: 100%;
  height: auto;
}

section .main-article,
section .doland-tremp-parent,
section .How-to-buystart,
section .footer-article {
  display: flex;
}

/* <!-- Navbar-start --> */
section nav {
  width: inherit;
  height: 104px;
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  border-bottom: 3px solid #d93731;
}

section nav .T6logo img {
  display: block;
  height: inherit;
  width: 11.25em;
  margin-left: 2em;
  height: 5em;
}

nav .T6links {
  width: 100%;
  padding: 0em 1em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
}

nav .T6links ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
}

.T6links ul li {
  /* margin: 0em 0.6em; */
}

.T6links ul li a {
  text-decoration: none;
  font-size: 1em;
  letter-spacing: -0.21px;
  line-height: 1.5em;
  text-transform: uppercase;
  font-weight: 800;
  font-family: Inter;
  color: #254a9a;
  padding: 0.5em 1em;
}

.T6buy-permit {
  letter-spacing: -0.18px;
  text-transform: uppercase;
  font-size: 1em;
  padding: 0.8em 3em;
  border-radius: 0.5em;
  border: transparent;
  background-color: #d93731;
  color: #fff;
  font-family: "Inter", sans-serif;
}

/* <!-- Navbar-End --> */

/* <!-- Main-section-start --> */
.T6main-section {
  background-repeat: no-repeat;
  background-size: cover;
}
.T6common-article {
  margin: 0em 7em;
  height: auto;
}

section article .T6main-left-side {
  width: 70%;
}

article .T6main-left-side h1 {
  margin-top: 1.2em;
  width: 70%;
  text-transform: uppercase;
  font-weight: 800;
  font-family: Inter;
  color: #fff;
  font-size: 4em;
  letter-spacing: -1.16px;
  line-height: 107.11px;
}

article .T6main-left-side p {
  width: 100%;
  word-break: break-all;
  margin-top: 1em;
  font-size: 1em;
  letter-spacing: -0.19px;
  line-height: 15.7px;
  font-family: Inter;
  color: #fff;
}

article .T6main-left-side .T6buy-permit-Parent {
  display: flex;
  flex-wrap: wrap;
  gap: 0em 1em;
  margin: 2em 0em 2em 0em;
}

.T6buy-pc {
  padding: 1em 2em;
  font-size: 1em;
  letter-spacing: -0.48px;
  text-transform: uppercase;
}

#T6PREMT-ON-X {
  background-color: #0f54e7;
}

section article .T6main-right-side {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* <!-- Main-section-End --> */
.T6main-section-last {
  width: 100%;
  height: 4.8em;
  background-color: #d93731;
}

/* <!-- doland tremp start --> */
.T6doland-tremp-left,
.T6doland-tremp-right,
.T6how-to-buystart-left,
.T6how-to-buystart-right {
  width: 50%;
  height: auto;
  margin: 5em 0em 5em 0em;
}

.T6doland-tremp-left h1 {
  font-size: 3em;
  letter-spacing: -0.68px;
  line-height: 71.64px;
  text-transform: uppercase;
  font-weight: 800;
  font-family: Inter;
  color: #254a9a;
}

.T6doland-tremp-left p {
  font-size: 1.15em;
  letter-spacing: -0.19px;
  line-height: 29.18px;
  width: 70%;
}

.T6doland-tremp-left .premt-is-a {
  font-size: 1.15em;
  letter-spacing: -0.19px;
  line-height: 29.18px;
}

.T6doland-tremp-left .T6social-media,
.T6footer-right .T6social-media {
  display: flex;
  flex-wrap: wrap;
  gap: 0em 2em;
  margin-top: 1em;
}

.T6doland-tremp-right img {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
}

/* <!-- doland tremp End --> */
/* <!-- How to buystart Start--> */

.T6how-to-buystart-left {
  width: 60%;
}

.T6how-to-buystart-left,
.T6how-to-buystart-right {
  margin-bottom: 0em;
}

.T6how-to-buystart-left h1 {
  font-size: 5em;
  letter-spacing: -1.16px;
  line-height: 107.11px;
  text-transform: uppercase;
  font-weight: 800;
}

.T6last-three {
  margin-top: 1em;
}

.T6buystart-right-child h1 {
  font-size: 1.1em;
  letter-spacing: -0.25px;
  line-height: 28.98px;
  text-transform: uppercase;
  font-weight: 500;
}

.T6buystart-right-child p {
  width: 90%;
  font-size: 14.3px;
  letter-spacing: -0.19px;
  line-height: 21.41px;
  margin-top: 1em;
}

.T6how-to-buystart-left img {
  margin: 3em 0em 0em 2.4em;
  width: 400px;
  height: 400px;
  margin-bottom: 40px;
}

/* <!-- How to buystart End--> */
/* <!-- MEMES Start--> */
.T6memes,
.T6in-the-news {
  padding: 0em 10em;
  height: auto;
}

.T6memesh1 {
 
  text-align: center;
  font-size: 3.1em;
  letter-spacing: -0.68px;
  line-height: 71.64px;
  text-transform: uppercase;
  font-weight: 800;
}

.T6memes a {
  display: block;
  text-align: center;
  font-size: 1em;
  text-decoration: underline;
  letter-spacing: -0.19px;
  line-height: 21.41px;
}

.T6memes img {
  margin: 1em 0em 2em 0em;
  width: 100%;
}

/* MEMES END */

/* In-the-news Start */

.T6in-the-newsh1 {
  padding-top: 1em;
  text-align: center;
  font-size: 3em;
  letter-spacing: -0.68px;
  line-height: 71.64px;
  text-transform: uppercase;
  font-weight: 600;
}

.T6in-the-news aside {
  display: flex;
  padding-bottom: 2em;
  margin-top: 2em;
  gap: 0em 2em;
}

.T6in-the-news aside .news-content {
  text-align: center;
  font-size: 1em;
  letter-spacing: -0.19px;
  line-height: 140%;
}
/* <!-- Footere Start--> */

/* <!-- Footere End--> */

.T6footer {
  padding-bottom: 2em;
}
.T6footer-article {
  padding: 2em 0em 2em 0em;
}
.T6footer-left .T6logo img {
  height: 7em;
  width: 15em;
}
.T6footer-article .T6footer-right {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.T6footer-article .T6footer-right .T6social-media {
  justify-content: flex-end;
  align-items: start;
  margin: 0em;
}

.T6social-icon img {
  height: 4em;
  width: 4em;
}
.T6footer-right p {
  margin: 1em 2em 0em 0em;
  font-size: 14.3px;
  letter-spacing: -0.19px;
  line-height: 21.41px;
  text-align: right;
}
.T6footer .T6All-rights-reserved {
  margin-top: 2em;
  font-size: 0.7em;
  letter-spacing: -0.19px;
  line-height: 15px;
  text-align: left;
  text-align: center;
}
/* Tablet view: Adjust padding and font sizes */
@media (max-width: 966px) {
  section nav {
    display: flex;
    justify-content: center;
    height: auto;
    padding: 1em;
    flex-wrap: wrap;
  }

  section nav .T6logo img {
    width: 9em;
    margin-left: 1em;
  }

  nav .T6links {
    padding: 1em 0;
    justify-content: center;
  }

  nav .T6links ul {
    flex-wrap: wrap;
    justify-content: center;
  }

  .T6links ul li {
    margin: 0.5em 0;
  }

  .T6buy-permit {
    padding: 0.5em 2em;
  }
}

@media (max-width: 768px) {
  article .T6main-left-side h1 {
    width: 100%;
    text-align: center;
  }
  section article .T6main-right-side {
    width: 100%;
  }
  section article .T6main-right-side img {
    width: 100%;
  }
  article .T6main-left-side .T6buy-permit-Parent {
    justify-content: center;
    gap: 1em;
  }
  section .T6main-article,
  section .T6doland-tremp-parent,
  section .T6How-to-buystart,
  section .T6footer-article {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .T6common-article {
    margin: 0em 3em;
  }
  .T6how-to-buystart-left h1 {
    text-align: center;
  }
  .T6how-to-buystart-right {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .T6doland-tremp-left .T6social-media{
    justify-content: center;
  }
  .T6how-to-buystart-left img {
    margin: 0;
    width: 100%;
  }
  .T6doland-tremp-left p,
  section article .T6main-left-side,
  .T6doland-tremp-left,
  .T6doland-tremp-right,
  .T6how-to-buystart-left {
    width: 100%;
  }
  .T6doland-tremp-right {
    margin: 0em 0em 3em 0em;
  }
  article .T6main-left-side p {
    text-align: center;
  }
  .T6in-the-news,
  .T6memes {
    padding: 0em 2em;
  }
  .T6in-the-news aside {
    flex-direction: column;

    gap: 20px;
  }
  .T6last-three {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .T6footer .T6All-rights-reserved {
    padding: 1.5em;
  }
  .T6in-the-news aside .T6news-content {
    text-align: start;
  }

  .T6in-the-news aside .T6news-content {
    width: 100%;
  }
  .T6footer-article .T6footer-right .T6social-media {
    justify-content: center;
  }
  .T6footer-right p {
    text-align: center;
  }
  .T6links ul {
    display: none;
  }
}
